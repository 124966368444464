import React, { useState } from "react";
import { Row } from "antd";
import Stats from "./Stats";

const TradeStats = ({ companyData }) => {
	const [hidState] = useState(
		companyData[0]?.focusExports == 0 && 
		companyData[0]?.focusImports == 0 &&
		companyData[0]?.focusCrossTrade == 0 &&
		companyData[0]?.focusEcommerce == 0
	)
	return (
		<Row>
			{!hidState && (
				<>
				<Stats percentage={companyData[0].focusExports} classification="Export" color="#67caca" />
				<Stats percentage={companyData[0].focusImports } classification="Import" color="#ffa500" />
				<Stats percentage={companyData[0].focusCrossTrade} classification="Cross-Trade" color="#e9cd6b" />
				<Stats percentage={companyData[0].focusEcommerce} classification="E-Commerce" color="#a680f6" />
				</>
			)}
		</Row>
	);
};

export default TradeStats;
