import React from "react";
import { Row, Col, Divider } from "antd";

const Destination = ({ companyData }) => {
	return (
		companyData?.length !== 0 && companyData.map(data => (
			(data.mawbAddress || data.notifyAddress || data.destinationNotes) &&
			<React.Fragment key={data.id}>
				<Row gutter={16} className="mt-8">
					<Col>
						<h3>MAWB / DESTINATION</h3>
					</Col>
					{(data.mawbAddress || data.notifyAddress) &&
						<Col span={12}>
							{data.mawbAddress && <>
								<h3>MAWB Information</h3>
								<h4>MAWB/CNEE INFORMATION</h4>
								<span dangerouslySetInnerHTML={{ __html: data.mawbAddress.replace(/(?:\r\n|\r|\n)/g, "<br>")}} />
							</>}
							{data.notifyAddress && <>
								<h4 className="mt-3">NOTIFY</h4>
								<span dangerouslySetInnerHTML={{__html:data.notifyAddress.replace(/(?:\r\n|\r|\n)/g, "<br>")}} />
							</>
							}
						</Col>}
					{data.destinationNotes &&
						<Col span={12}>
							<h3>Destination Notes</h3>
							<span dangerouslySetInnerHTML={{ __html: data.destinationNotes.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></span>
						</Col>}
				</Row>
				<Divider />
			</React.Fragment >
		))
	);
};

export default Destination;
