import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateCompany,
  updateSelectedExpertises,
  updateSelectedExpertisesV2,
  getCompany

} from "actions/companyActions";
// import { getExpertiseList } from "actions/dataActions"; // comment reason 101
import { checkCondition, editVendorCondition, findExpertise, findExpertiseV2, findExpertiseV3, formatSelectedExpertisesV2, generateId, hideForNetwork, tradeLaneCondtion, vendorDocContion, vendorHidden } from "utils/functions";
import ExpertiseListing from "./ExpertiseListing";
import QuestionsList from "./QuestionsList";
import { message, Checkbox, Divider, Row, Col, notification, Icon } from "antd";
import ImportExportCountries from "./ImportExportCountries";
import styled from "@emotion/styled";
import FocusSlider from "./FocusSlider";
import Classification from "./Classification";
import Tradelane from "./Tradelane";
import SubGroup from './SubGroup'
import { debounce } from "lodash";
import { HIDE_NETWORK } from "utils/enum";
import axios from "axios";
import { api_v, base_url } from "utils/settings";
import Dropzone from "components/ui-elements/Dropzone";
import { uploadVendorDocument } from "actions/uploadActions";

const MemoizedChildComponentA = React.memo(QuestionsList);
// import SubIndustriesExpertiese from "../../common/subIndustriesExpertiese"

const ExpertiseSelection = styled(Checkbox.Group)`
  min-height: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 0.7em;
  margin-bottom: 24px;
`;

class Expertise extends Component {
  constructor(props) {

    super(props);
    this.state = {
      resultChk: [],
      selectedExpertieseIds: [],
      selectedSubIndustry: this.props.subIndustry,
      checkedValObj: this.props.expertisestoCheckedValues,
      selectedExpertiseList: this.props.expertises,
      selectedSubIndustryIds: this.props.subIndustry?.map(({ id }) => id),
      reRender: false,
      maxExpertises: /* this.props.classification  === "Vendor" */  (this.props?.subIndustry?.filter(item => item.id === 7)?.length) !== 0 ? 3 : 4,
      vendorDocuments: this.props.companyData?.document
    };
  }
  makeRerender() {
    this.setState(prev => ({ reRender: !(prev.reRender) }))
    //  axios.get(`${base_url}/${api_v}company/${690}/profile`)
    //  .then(res => {
    //   console.log("checking in axios.....",res)
    //  })
    //  this.props.getCompany(`690`)
  }
  componentDidMount() {

    //  this.props.getExpertiseList(this.props.classification);  // comment reason 101
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.expertises !== this.props.expertises) {
      // Update the state when expertises prop changes
      this.setState({
        selectedExpertiseList: this.props.expertises,
      });
    }
    if (prevProps.subIndustry !== this.props.subIndustry) {
      // Update the state when expertises prop changes
      this.setState({
        selectedSubIndustry: this.props.subIndustry,
      });
    }

    if (this.state.checkedValObj !== prevState.checkedValObj) {
      const expertiseArray = Object.values(this.state.checkedValObj).flat();

      this.setState(
        {
          resultChk: expertiseArray
        }
      );
      /*  let value = []
       const expertiseCheckedIdsArray = Object.values(this.state.checkedValObj).flat();
       value.push(...expertiseCheckedIdsArray)
       this.setState({
       selectedExpertieseIds: value
       }) */

    }

    if (this.state.vendorDocuments !== prevState.vendorDocuments) {
      let document = this.state.vendorDocuments.map(({ id }) => `/v1/documents/${id}`);
      await this.props.updateCompany({ document }, this.props.companyData.id);
    }
  }

  // Checkbox selection
  onChange = (list, checkedValues) => {
    const { classification, companyId, expertiseList } = this.props;

    // this.setState({
    //   selectedExpertiseList: checkedValues
    // });
    this.setState(prevState => ({
      checkedValObj: {
        ...prevState.checkedValObj,
        [list]: checkedValues,
      }
    }));

    const apiUpdate = debounce(() => {
      // const payload = [];
      // payload.push(this.state.resultChk);

      const addData = formatSelectedExpertisesV2(this.state.resultChk);
      this.props
        .updateSelectedExpertisesV2(
          addData,
          companyId,
          "expertiese"
        )
        .then(() => {
          message.success("Information has been updated.");
        });
    }, 500);

    apiUpdate();
  };

  uploadFiles(files) {
    let data;
    files.forEach((file) => {
      // Data for Upload
      data = new FormData();
      data.append("file", file);
      data.append("source", "directory");

      // Data for Preview and progress
      const previewData = {
        id: generateId(),
        progress: 0,
        size: file.size,
        text: file.name.replace(/\.[^/.]+$/, ""),
        type: file.type,
      };

      // API request
      this.uploadDocument(data, previewData);
    });
  }

  async uploadDocument(data, previewData) {
    try {
      const res = await this.props.uploadVendorDocument(data, previewData);
      let { id, path, originalName, source, branchId } = res;
      const vendorDocuments = [...this.state.vendorDocuments, { id, path, originalName, source, branchId }]
      this.setState({ vendorDocuments })

    } catch (error) {
      const { response } = error;
      if (response?.data?.detail)
        notification.error({ description: response?.data?.detail })
      else
        notification.error({ description: "Something went wrong." })
    }
  }

  deleteDocument(index) {
    let vendorDocuments = [...this.state.vendorDocuments];
    vendorDocuments.splice(index, 1);
    this.setState({ vendorDocuments })
    // deleteDocument
    // setVendorDocuments(list);
  };

  render() {
    const { maxExpertises, selectedExpertiseList, selectedSubIndustry, selectedSubIndustryIds } = this.state;
    const { classification, focus, companyId, auth, companyData } = this.props;

    const isAdmin = auth.user.roles.includes("ROLE_ADMIN");
    // const distinctSubIndustries = [...new Set(this.props?.subIndustry.map(item => item.name))];


    let expertiseListArray = [...this.props?.subIndustry?.map(a => {
      return a.expertieses;
    }).flat()]

    // List formated for CheckBox.Group comment reason 101
    let expertiseList = expertiseListArray?.map(a => ({
      label: a?.name,
      value: a?.id
    }));


    // Disable not selected, and limit for maxExpertise
    // if (selectedExpertiseList.length >= maxExpertises) {
    //   expertiseListArray.forEach((item, i) => {
    //     if (!selectedExpertiseList.includes(item.id)) {
    //       expertiseListArray[i].disabled = true;
    //     }
    //   });
    // }
    // console.log("woking","")
    return (
      <>
        <div className="card">
          <div className="card-header">
            <h3>Networks</h3>
          </div>
          <div className="card-body">
            <div className="expertise-classificationPanel imagesAndCheckboxes">
              {
                /*    isAdmin && */
                <>
                  {/* <Classification
                companyDataId={focus.id}
                companyId={companyId}></Classification> */}
                  {/*  <SubGroup /> */}
                </>
              }

            </div>
            <p style={{ marginBottom: "20px" }}>
              Chose maximum {maxExpertises} of below industry expertise that
              your company is specialized at
            </p>
            <div className="customSecionCard">
              <SubGroup isAdmin={isAdmin} makeRerender={this.makeRerender.bind(this)} />
              {/* {103} */}
              {/* {classification === "Air Partner" ? (
                <>
                  <ExpertiseListing
                    expertiseList={expertiseList}
                    expertises={selectedExpertiseList}
                    classification={classification}
                    companyId={companyId}
                  ></ExpertiseListing>
                </>
              ) : null} */}
              {/* {103} */}
            </div>


            {/* here is iteration for all sunindustry in order to showing all checkboxes in groupes*/}
            {/*   <SubIndustriesExpertiese subIndustries={this.props?.subIndustry} companyData={this.props?.companyData} handleExpertiseSelection={this.onChange} selectedExpertieseIds={this.props.selectedExpertieseIds}/> */}
            {/*  {this.props?.subIndustry?.map((sub) => {
              let expertiseListnew = sub.expertieses?.map(a => ({
                label: a.name,
                value: a.id,
                key: sub.name           
              }));
              return (
                <>
                  <div className="ant-checkbox-group expertise-box-group expertise-box-group-custom">
                    <div className="expertise-box">
                      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: "20px" }} className="expertise-box-head">
                        <div className="logoAndBadge">
                          <img src={sub.industryLogo} className="logo-img" />
                          <span style={{ marginLeft: "20px" }} className="expertise" > Expertise </span>
                        </div>
                      </div>

                      < ExpertiseSelection
                        className="userExpertise"
                        options={expertiseListnew}
                        defaultValue={selectedExpertiseList}
                        onChange={(check) => { this.onChange(sub.name, check) }}
                      // disabled={!isAdmin}
                      ></ExpertiseSelection>
                    </div>
                  </div>
                </>
                )
            })}
 */}

          </div>
        </div>
        {(vendorHidden(selectedSubIndustry)) ?
          <div className="card">
            <div className="card-body">
              {/*           {classification !== "Vendor" ? (
            <>
              <Tradelane expertise ></Tradelane>
            </>
          ) : null}
          {classification !== "Vendor" ? (
            <>
              <FocusSlider
                expertise
                focus={focus}
                companyDataId={focus.id}
                companyId={companyId}
              ></FocusSlider>
            </>
          ) : null}       */}
              {vendorHidden(selectedSubIndustry) ? (
                tradeLaneCondtion(selectedSubIndustry) ? <Tradelane selectedSubIndustry={selectedSubIndustry} expertise /> : null
              ) : null}
              {checkCondition(selectedSubIndustry) ? (
                <>
                  <FocusSlider
                    fileName="expertise"
                    expertise
                    focus={focus}
                    companyDataId={focus.id}
                    companyId={companyId}
                  ></FocusSlider>
                </>
              ) : null}
              {/* <div className="card"> */}
              {/*  <div className="card-header">
            <h3>Industry expertise</h3>
          </div> */}
              {/* <div className="card-body"> */}
              {/* {classification !== "Vendor" ? (
            <> */}
              {vendorHidden(selectedSubIndustry) ? <ImportExportCountries fileName="expertise" selectedExpertieseIds={selectedExpertiseList}
                selectedSubIndustryIds={selectedSubIndustry.map(({ id }) => id)}
              /> : ""}
              {/* <Divider></Divider> */}
              {/* </>
          ) : null} */}
              {/* </div>
        </div> */}

              {/* <div className="card"> */}
              {/* <div className="card-header">
            <h3>Questions</h3>
          </div> */}
              {/* <div className="card-body"> */}
              {/*  <div className="expertise-classificationPanel imagesAndCheckboxes">
            </div> */}
              {vendorHidden(selectedSubIndustry) ?
                <div className="customSecionCard">
                  <ExpertiseListing
                    fileName="expertise"
                    fileStatus="registration"
                    expertiseList={expertiseList}
                    expertises={[1]}
                    classification={classification}
                    companyId={companyId}
                    selectedSubIndustryIds={selectedSubIndustry.map(({ id }) => id)}
                  ></ExpertiseListing>
                </div> : ""}
              <div className="customSecionCard">
                <MemoizedChildComponentA
                  // expertiseList={expertiseList}
                  companyData={companyData}
                  userStatus='admin'
                  subIndustry={selectedSubIndustry}
                  expertises={selectedExpertiseList}
                  companyId={companyId}
                  setInputFieldData={() => { }}
                  setAllQuestionsData={() => { }}
                  setValidationErrorMsg={() => { }}
                  setAnswerErrors={() => { }}
                  fileStatus="expertise"
                  setNo={() => { }}
                // classification={classification}
                />
              </div>

              {/* {vendorHidden(selectedSubIndustry) ?
                <div className="customSecionCard">
                  <QuestionsList
                    companyData={companyData}
                    userStatus='admin'
                    subIndustry={selectedSubIndustry}
                    expertises={selectedExpertiseList}
                    companyId={companyId}
                    setInputFieldData={() => { }}
                    setAllQuestionsData={() => { }}
                    setValidationErrorMsg={() => { }}
                    setAnswerErrors={() => { }}
                    fileStatus="expertise"
                    setNo={() => { }}
                  >
                  </ QuestionsList>
                </div> : ""} */}
              {/* </div> */}
              {/* </div> */}
            </div>
          </div> : null}

        {editVendorCondition(selectedSubIndustry) ?
          <div className="card">
            <div className="card-body">
              <Row>
                <Col>
                  <div className="forHeading" style={{ margin: 0, color: 'rgba(0, 0, 0, 0.65)' }}>Documents</div>
                </Col>
              </Row>
              {(selectedSubIndustry.map(({ id }) => id).length == 1 && selectedSubIndustry.map(({ id }) => id).includes(7)) && (
                <div className="customSecionCard">
                  <MemoizedChildComponentA
                    // expertiseList={expertiseList}
                    companyData={companyData}
                    userStatus='admin'
                    subIndustry={selectedSubIndustry}
                    expertises={selectedExpertiseList}
                    companyId={companyId}
                    setInputFieldData={() => { }}
                    setAllQuestionsData={() => { }}
                    setValidationErrorMsg={() => { }}
                    setAnswerErrors={() => { }}
                    fileStatus="expertise"
                    setNo={() => { }}
                  // classification={classification}
                  />
                </div>
              )}
              <Row>
                <Col sm={24} lg={24} xs={24} className="document-wrapper">
                  {this.state.vendorDocuments.map((doc, index) => {
                    return (
                      <div
                        key={`vendorDocument_${index}`}
                        className="document"
                      >
                          <Icon
                            type="file"
                            style={{ fontSize: 20, marginRight: 5 }}
                          // theme="twoTone"
                          />
                          <span>{doc.originalName}</span>
                          <Icon
                            type="delete"
                            onClick={this.deleteDocument.bind(this, [index])}
                            style={{ cursor: "pointer" }}
                          />
                      </div>
                    );
                  })}
                </Col>
                <Col sm={24} lg={24} xs={24}>
                  <Dropzone handleDrop={this.uploadFiles.bind(this)} />
                </Col>
              </Row>
            </div>
          </div>
          : null}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  companyId: state.company.data.id,
  focus: state.company.data.companyData[0],
  classification: state.company.data?.companySettings[0]?.classification,
  expertises: findExpertiseV2(state.company.data).map(a => a.id),
  expertisestoCheckedValues: findExpertiseV3(state.company.data),
  specializationList: state.data.specializationList,
  companyData: state.company.data,
  subIndustry: state.company.data.subIndustry,
  // expertiseList: state.data.expertiseList,    // comment reason 101
  auth: state.auth
});

export default connect(mapStateToProps, {
  // getExpertiseList,   // comment reason 101
  updateCompany,
  updateSelectedExpertises,
  updateSelectedExpertisesV2,
  getCompany,
  uploadVendorDocument
})(Expertise);
