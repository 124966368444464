import React, { useEffect, useState } from "react";
import CompanyStatus from "./CompanyStatus";
import CompanyHeader from "./CompanyHeader";
import CompanyHeaderAccounting from "./CompanyHeaderAccounting";
import ExpertiseListing from "./Expertise/ExpertiseListing";
import CompanyTradelane from "./CompanyTradelane";
import CompanyBranches from "./CompanyBranches";
import CompanyImportExport from "./CompanyImportExport";
import { findExpertise, findSubIndustry } from "utils/functions";
import styled from "@emotion/styled";
import { Row, Col, Button } from "antd";
import { NavLink, useHistory, useLocation } from "react-router-dom";

const NetworkCard = styled("div")`
  outline: ${(props) => props.isMarked && "2px solid #337ab7"};
`;
const CompanyCard = ({ item, toggleEdit, containerWidth, auth, borderClass }) => {
  const roles = localStorage.getItem("rolesUser");
  const [logoArray, setLogoArray] = useState([]);
  const [acreditation, setAcreditation] = useState([]);
  const [isMarked, toggleMarked] = useState(false);
  const [selectedSubIndustryIds] = useState(item?.subIndustry?.map(({id}) => id));
  let history = useHistory();
  let location = useLocation();

  // Get the current URL
  const currentUrl = window.location.href;

  // Create a URLSearchParams object with the query parameters
  const urlParams = new URLSearchParams(new URL(currentUrl).search);

  // Get the value of the 'status' parameter
  const userStatus = urlParams.get('status');


  const selectCard = () => {
    toggleMarked((isMarked) => !isMarked);
  };

  const handleEdit = () => {
    const isAdmin = auth.user.roles.includes("ROLE_ADMIN");

    if (isAdmin) {
      toggleEdit(item.id);
    } else {
      history.push(`/company-profile/${item.id}`);
    }
  };
  useEffect(() => {
    if (item?.companyPartners?.length > 0) {
      const array = [];
      const element = item?.companyPartners?.forEach((element) =>
        element?.status === "1" ? array.push(element) : []
      );

      setLogoArray(array);
    }
  }, [item?.companyPartners]);

  console.log("checking..in company card..###",selectedSubIndustryIds)
  console.log("item>>>>", item)
  return (
    location.pathname == "/network" ?
      < NetworkCard
        className={`card card__network m-4 ${JSON.parse(localStorage.getItem('rolesUser') === 'ROLE_ADMIN') && borderClass}`
        }
        key={item.id}
        isMarked={isMarked}
      >
        <CompanyHeader
          companySettings={item?.companySettings[0]}
          subIndustry={findSubIndustry(item)}
          userCompanyId={item.id}
          roles={roles}
          name={item.companyName}
          city={item.city || ""}
          classification={item?.companySettings[0]?.classification}
          country={item.countries ? item.countries.countryname : ""}
          logo={item.logo}
          id={item?.id}
          focus={item.companyData[0]}
          status={item?.companySettings[0]?.status}
          toggleEdit={toggleEdit}
          approved={item?.companySettings[0]?.approved}
          auth={auth}
          licences={item?.licences}
          selectCard={selectCard}
          itemAccounting={item}
        />
        <div className="card-body">
          <Row>
            <Col span={23}>
              <Row gutter={10}>
                {findSubIndustry(item)?.length ?
                  <Col span={5}>
                    <ExpertiseListing
                      subIndustry={findSubIndustry(item)}
                      expertise={item.expertiese}
                    /*  expertise={findExpertise(item)}
                     containerWidth={containerWidth}
                     classification={item?.companySettings[0]?.classification}
                     companyId={item.id} */
                     selectedSubIndustryIds={selectedSubIndustryIds}
                       
                    />
                  </Col> : ""}
                <Col span={5}>
                  <CompanyTradelane items={item.specializations} />
                </Col>

                <CompanyImportExport
                  importCountries={item.airImportCountry}
                  exportCountries={item.airExportCountry}
                />

                <Col span={4}>
                  <CompanyBranches branches={item.branch}></CompanyBranches>
                </Col>
              </Row>
            </Col>

            <Col span={1} className="toggleButton">
              {localStorage.status !== "Approved" ||
                (item?.companySettings[0]?.status === "Not Finished" &&
                  !item?.companySettings[0]?.approved)
                ?
                null
                :
                (JSON.parse(localStorage.getItem('rolesUser') !== 'ROLE_ADMIN') && (userStatus === 'Resigned' || userStatus === 'Suspended'))
                  ? null :
                  < Button
                    type="primary"
                    className="networkActions"
                    onClick={() => handleEdit()}
                  >
                    ...
                  </Button>
              }
            </Col>
          </Row>
          {logoArray?.length > 0 ? (
            <Row
              gutter={16}
              type="flex"
              justify="space-around"
              // align="middle"
              className="mt-8"
            >
              <Col span={4}>
                <h3>Our Tools</h3>
              </Col>
              <Col span={20} className="text-center1">
                <div class="partner-logo-right">
                  {logoArray?.map((element) => {
                    const { partnerLink, partnerLogo, partnerName, status } =
                      element;

                    return status === "1" ? (
                      <nav className="mx-1" key={partnerLink}>
                        <NavLink
                          className="nav-item"
                          to={{
                            pathname: partnerLink,
                          }}
                          target="_blank"
                        >
                          <img
                            src={partnerLogo}
                            alt={partnerName}
                            className="partnerIcon"
                          />
                        </NavLink>
                      </nav>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
        {
          item?.companySettings[0] ? (
            <CompanyStatus
              companySettings={item?.companySettings[0]}
              companyInfo={item?.companyInfo[0]}
              companyName={item.companyName}
              companyId={item.id}
              Companypartner={item?.companyPartners}
              auth={auth}
              selectCard={selectCard}
            />
          ) : ""
        }
      </NetworkCard >
      :
      <div className="accounting-network-card-wrapper">
        <NetworkCard
          className={`card card__network m-4 ${JSON.parse(localStorage.getItem('rolesUser') === 'ROLE_ADMIN') && borderClass}`}
          key={item.id}
          isMarked={isMarked}
        >
          <CompanyHeaderAccounting
            companySettings={item?.companySettings[0]}
            subIndustry={findSubIndustry(item)}
            userCompanyId={item.id}
            roles={roles}
            name={item.companyName}
            city={item.city || ""}
            classification={item?.companySettings[0]?.classification}
            country={item.countries ? item.countries.countryname : ""}
            logo={item.logo}
            id={item?.id}
            focus={item.companyData[0]}
            status={item?.companySettings[0]?.status}
            toggleEdit={toggleEdit}
            approved={item?.companySettings[0]?.approved}
            auth={auth}
            licences={item?.licences}
            selectCard={selectCard}
            itemAccounting={item}
          />
        </NetworkCard >
      </div>
  );
};

export default CompanyCard;
