import React, { useState, useEffect } from "react";
import { Row, Col, Collapse, Tooltip } from "antd";
import { RightOutlined } from '@ant-design/icons';
import AirCargoNeutral from "components/network/company-card/Expertise/AirCargoNeutral";
import ExpertiseItem from "components/network/company-card/Expertise/ExpertiseItem";
import { base_url } from "utils/settings";
import { useSelector } from "react-redux";
import UserQuestionAnswerList from "./UserQuestionAnswerList"
import Network from "./Network"
import CompanyOverview from "./CompanyOverview"
import { NavLink } from "react-router-dom";

const { Panel } = Collapse;

const expertiseIcons = {
  airAndNeutral: "nav.svg",
  1: "nac.svg",
  2: "nac.svg",
  3: "nav.svg",
  4: "nac.svg",
  5: "nav.svg",
  6: "nap.svg",
  7: "nap.svg",
  8: "nax-1.svg",
  9: "nax-2.svg",
  10: "nav.svg",
  11: "nax-1.svg",
  12: "nap.svg",
  13: "nav.svg",
};

const Overview = ({ company }) => {

  const [active, setActive] = useState();
  const logoContent = useSelector((state) => state.auth.logoData);
  const [logoArray, setLogoArray] = useState([]);

  const groupedObjects = company?.expertiese?.reduce((result, obj) => {
    const subIndustryId = obj.subIndustry.id;
    if (!result[subIndustryId]) {
      result[subIndustryId] = [];
    }
    result[subIndustryId].push(obj);
    return result;
  }, {});

  // const airNeutralExpertise = (expertise) => {
  // 	const air = expertise.some(e => e.id === 1);
  // 	const neutral = expertise.some(e => e.id === 2);

  // 	if (air && neutral) {
  // 		const title = getTitle(expertiseIcons.airAndNeutral, expertise[0].industryExpertise + ' / ' + expertise[1].industryExpertise);
  // 		return <Panel header={title} showArrow={false} className="company-profile-expertise-panel">
  // 			<AirCargoNeutral air={air} neutral={neutral}></AirCargoNeutral>
  // 		</Panel>;
  // 	} else {
  // 		return <Panel header={getTitle(expertiseIcons[air && neutral ? 'airAndNeutral' : air ? 1 : 2], expertise[0].industryExpertise)} showArrow={false} className="company-profile-expertise-panel">
  // 			<AirCargoNeutral air={air} neutral={neutral}></AirCargoNeutral>
  // 		</Panel>;
  // 	}
  // }
  useEffect(() => {
    if (logoContent?.length > 0) {
      const array = [];
      const element = logoContent.forEach((element) =>
        element?.status === "1" ? array.push(element) : []
      );
      setLogoArray(array);
    }
  }, [logoContent]);

  function getTitle(icon, title) {
    return (
      <div className="specializationBox">
        {/* <RightOutlined /> */}
        <span className="specializationSpan">
          {title}
          {/* <div style={{backgroundColor:'black'; }}> */}
          <img
            src={icon}
            /*   style={{ backgroundColor: 'black' }} */
            // src={base_url + "/bundles/profile/images/" + icon}
            width="40"
            height="40"
            className="mr-2"
          />
          {/* </div> */}

        </span>
      </div>
    );
  }

 /*  const handleChange = (key) => {
    const air = company.airIndustryExpertise.some(
      (expertise) => expertise.id === 1
    );
    const neutral = company.airIndustryExpertise.some(
      (expertise) => expertise.id === 2
    );
    const checkAirId = key.some((item) => item === "1");
    const checkNeutralId = key.some((item) => item === "2");
    if (checkAirId && checkNeutralId) {
      key = key.filter((value) => value !== "1" && value !== "2");
      return setActive(key);
    }
    if (checkAirId || checkNeutralId) {
      if (air && neutral && checkAirId) {
        key = key.filter((value) => value !== "1" && value !== "2");
        key.push("2");
        return setActive(key);
      } else {
        setActive(key);
      }
    } else {
      setActive(key);
    }
  }; */
  function getTitleFor7(icon, title) {
    return (
      <div className="specializationBox">
        {/* <RightOutlined /> */}
        <span>
          <img
            src={icon}
            // style={{ backgroundColor: 'black' }}
            // src={base_url + "/bundles/profile/images/" + icon}
            width="40"
            height="40"
            className="mr-2"
          />
          {title}
          {/* <div style={{backgroundColor:'black'; }}> */}

          {/* </div> */}

        </span>
      </div>
    );
  }
  return (
    <div className="px-8">
      <Row >
        <CompanyOverview companyData={company} />
        <Row className="mt-8">
          <Col span={24}>
            {JSON.stringify(company?.subIndustry?.filter(item => item.id === 7).length === 0)}
            {company?.subIndustry?.filter(item => item.id === 7).length === 0
              &&
              <>
                {Object.keys(groupedObjects).length > 0 ?
                  <>
                    {/* <h3>SPECIALIZATIONS</h3> */}
                    <h3>Our Network/s</h3> 
                    <Network companyData={company} />
                  </> : ""}
              </>
              /*   :
                <>
                  {company.expertiese.length > 0 ?
                    <>
                      <h3>SPECIALIZATIONS</h3>
                      <div className="Display">
                        {company.expertiese.map((expertise) => {
                          return (
                            <Panel header={getTitleFor7(
                              expertise?.subIndustry?.deactiveImg,
                              expertise.name
                            )}
                              key={expertise.id}
                            >
                            <UserQuestionAnswerList expertiseId={expertise.id} /> 
                            </Panel>
                          )
                        }
  
                        )}
                      </div>
  
                    </> : ""}
                </> */
            }
          </Col>
        </Row>

        {/*   <Collapse
          style={{ backgroundColor: "#fff" }}
          activeKey={active}
          onChange={(e) => handleChange(e)}
        >
        
          {company.expertiese.length ? (
            <> */}
        {/* Air Cargo and Neutral Air Consolidator */}
        {/* {airNeutralExpertise(company.airIndustryExpertise)} */}
        {/* All other expertise */}
        {/*   {company.expertiese.map((expertise) => {
                return (
                  <Panel
                    header={getTitle(
                      // expertiseIcons[expertise?.subIndustry?.activeImg],
                      expertise?.subIndustry?.activeImg,
                      expertise.name
                    )}
                    showArrow={false}
                    key={expertise.id}
                    className="company-profile-expertise-panel"
                  >

                    <ExpertiseItem
                      expertiseId={expertise.id}
                      title={expertise.name}
                      companyId={company.id}
                    ></ExpertiseItem>
                  </Panel>
                );
              })} */}
        {/*    </>
          ) : null}
        </Collapse> */}
        {/*         <ul
          className="list-blank"
          style={{ border: "1px solid #d9d9d9", cursor: "pointer" }}
        >
          {company.associateIndustryExpertise.length
            ? company.associateIndustryExpertise.map((expertise) => (
              <li
                key={expertise.id}
                className="p-3"
                style={{
                  borderBottom: "1px solid #d9d9d9",
                  cursor: "pointer",
                }}
              >
                <img
                  src={`${base_url}/bundles/profile/images/nap.svg`}
                  width="30"
                  height="30"
                  className="mr-2"
                />{" "}
                {expertise.industryExpertise}
              </li>
            ))
            : null}
         
        </ul> */}
      </Row>
      {/* <Row gutter={16} className="mt-8">
        {company.airExportCountry.length !== 0 ? (
          <Col span={8}>
            <h3>Top Export Countries</h3>
            {company.airExportCountry.map((country) => (
              <React.Fragment key={country.id}>
                <span style={{ color: "#67caca" }}>
                  {country.country.countryname}
                </span>
                <br />
              </React.Fragment>
            ))}
          </Col>
        ) : null}
        {company.airImportCountry.length !== 0 ? (
          <Col span={8}>
            <h3>Top Import Countries</h3>
            {company.airImportCountry.map((country) => (
              <React.Fragment key={country.id}>
                <span style={{ color: "#FFA500" }}>
                  {country.country.countryname}
                </span>
                <br />
              </React.Fragment>
            ))}
          </Col>
        ) : null}
        {company.specializations?.length !== 0 && (
          <Col span={8}>
            <h3>Top Tradelanes</h3>
            {company.specializations.map((specialization) => (
              <React.Fragment key={specialization.id}>
                <span>{specialization.specialization}</span>
                <br />
              </React.Fragment>
            ))}
          </Col>
        )}
      </Row>
      {company.licences?.length !== 0 && (
        <Row
          gutter={16}
          type="flex"
          justify="space-around"
          align="middle"
          className="mt-8"
        >
          <Col span={6}>
            <h3>ACCREDITATIONS</h3>
          </Col>
          <Col span={18} className="text-center">
            {company.licences.map((licence) => (
              <Tooltip
                key={licence.id}
                title={licence.acreditation + "REF No." + licence.refNo}
              >
                <a
                  className="mx-1"
                  style={{
                    width: "60px",
                    height: "60px",
                    display: "inline-block",
                  }}
                >
                  <img src={licence.imageUrl} style={{ width: "100%" }} />
                </a>
              </Tooltip>
            ))}
          </Col>
        </Row>
      )} */}
{/* move to our tools in company overview */}
{/*       {logoArray?.length > 0 ? (
        <Row
          gutter={16}
          type="flex"
          justify="space-around"
          // align="middle"
          className="mt-8"
        >
          <Col span={6}>
            <h3>Our Tools</h3>
          </Col>
          <Col span={18} className="text-center1">
            <div class="partner-logo-right">
              {logoArray?.map((element) => {
                const { partnerLink, partnerLogo, partnerName, status } =
                  element;
                return status === "1" ? (
                  <nav className="mx-1">
                    <NavLink
                      className="nav-item"
                      to={{
                        pathname: partnerLink,
                      }}
                      target="_blank"
                    >
                      <img
                        src={partnerLogo}
                        alt={partnerName}
                        className="partnerIcon"
                      />
                    </NavLink>
                  </nav>
                ) : (
                  ""
                );
              })}
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default Overview;
