import React from "react";
import { Row } from "antd";

const History = ({ companyInfo, marginClass }) => {
	return (		
		<Row className={marginClass ? marginClass : null}>
			<h3>About Company</h3>			
			<p style={{ whiteSpace: 'pre-line' }}>{companyInfo[0].description}</p>
		</Row>
	);
};

export default History;
