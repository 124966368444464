import React, { useState, useEffect } from "react";
import { Row, Col, Tooltip } from "antd"
import TopImportExportData from "./TopImportExportData"
import Accrediatations from "./Accrediations";
import defaultProfileLogo from "theme/assets/img/profile-logo.png";
import TopAirportsAndAirportsBranches from "./TopData/TopAirportsAndAirportsBranches";
import TopCountriesAndTradelanes from "./TopData/TopCountriesAndTradelanes";
import OurTools from "./OurTools";
import { useSelector } from "react-redux";

const CompanyOverview = ({ companyData }) => {

    const logoContent = useSelector((state) => state.auth.logoData);
    const [logoArray, setLogoArray] = useState([]);
    const [airports, setAirports] = useState([])
    const [showExportImport] = useState(
        companyData?.airExportCountry?.length !== 0 &&
        companyData?.airImportCountry?.length !== 0 &&
        companyData?.expNacDestAirport?.length !== 0 &&
        companyData?.expNacTopAirline?.length !== 0
    )

    useEffect(() => {
        if (logoContent?.length > 0) {
            const array = [];
            //   console.log(logoContent,"logoContent")
            const element = logoContent.forEach((element) =>
                element?.status === "1" ? array.push(element) : []
            );
            setLogoArray(array);
        }
    }, [logoContent]);

    useEffect(() => {
        let allAirports = new Set();
        /* if (companyData) {
            if (companyData?.companyInfo) {
                if (companyData?.companyInfo[0]?.companyAirport) {
                    allAirports.push(companyData?.companyInfo[0]?.companyAirport?.iata)
                }
            }
            if (companyData?.branch) {
                companyData.branch.map((value) => {
                    if (value?.branchInfo[0]?.branchAirport) {
                        allAirports.push(value?.branchInfo[0]?.branchAirport?.iata)
                    }
                });
            }
        } */

        if (companyData) {
            if (companyData?.companyInfo) {
                if (companyData?.companyInfo[0]?.companyAirport) {
                    allAirports.add(companyData?.companyInfo[0]?.companyAirport?.iata); // Add company airport to the set
                }
            }
            if (companyData?.branch) {
                companyData.branch.forEach((value) => {
                    if (value?.branchInfo[0]?.branchAirport) {
                        allAirports.add(value?.branchInfo[0]?.branchAirport?.iata); // Add branch airport to the set
                    }
                });
            }
        }
        setAirports(allAirports ? Array.from(allAirports) : []);
        console.log('check company data',companyData);
    }, [companyData]);
  
    return (
        <div>
            {/* Company Data */}
            <Row gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
            }}
                className="mt-8">
                <Col className="gutter-row" span={8}>
                    <img src={companyData.logo[0]?.path ? companyData.logo[0]?.path : defaultProfileLogo} alt="logo" className={`${companyData.logo[0]?.path ? "company-logo-company-profile" : "default-company-profile-logo"}`} />
                </Col>
                <Col className="gutter-row" span={16}>
                    <Row gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,

                    }}
                    >
                        {/* ---------Top Tradelanes----------- */}
                        <Col className="gutter-row tradelanes" lg={24} xl={12}>
                            {companyData?.specializations?.length !== 0 &&
                                <TopCountriesAndTradelanes title="Top tradelanes" data={companyData?.specializations} />}
                        </Col>
                        {/* ---------Airport Branches------------ */}
                        <Col className="gutter-row Airport" lg={24} xl={12}>
                            {airports?.length !== 0 &&
                                <TopAirportsAndAirportsBranches data={airports /* companyData?.nacOriginAirports */} title="Airport(s)" />
                            }
                        </Col>
                    </Row>
                    <Row gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                        className="mt-8">
                        {/* -----------Accrediations----------------- */}
                        <Col span={18}>
                            {companyData.licences?.length !== 0 && (
                                <Accrediatations data={companyData?.licences} />
                            )}
                        </Col>
                    </Row>
                    <Row gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                        className="mt-8">
                        {/* -----------Accrediations----------------- */}
                        <Col span={18}>
                            {logoArray?.length !== 0 && (
                                <OurTools />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* ---------------------Export Data------------------------- */}
            {
                (companyData && showExportImport) && (<Row className="mt-8"> <TopImportExportData className="export-bottom-line bottom-line" title="EXPORT" companyData={companyData} /></Row>)
            }
            {/* ----------------------Import Data------------------------- */}
            {
              (companyData && showExportImport) &&  (<Row className="mt-8">  <TopImportExportData className="import-bottom-line bottom-line" title="IMPORT" companyData={companyData} /></Row>)
            }
        </div>
    )
}
export default CompanyOverview