import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Header from "components/layout/Header";
import MobileAlert from "components/ui-elements/MobileAlert";
import { DOMAIN } from "utils/enum";
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal, notification } from "antd";
import axios from 'axios';
import { api_v, base_url } from "utils/settings";

const PrivateRoute = ({ component: Component, auth, title, ...rest }) => {

  const { domain } = useSelector((state) => state.common)
  const authState = useSelector(state => state.auth);
  const [popup] = useState(authState.popup?.hasOwnProperty('popupImage') ? authState.popup : JSON.parse(localStorage.getItem('popup')));
  const history = useHistory();
  const [showCloseBtn, setShowCloseBtn] = useState(popup?.hasOwnProperty('status') && popup.status == 1);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeStatus = async (status) => {
    if (status == 2)
      setLoading(true);
    try {
      const { data } = await axios.post(`${base_url}/${api_v}user-popup-status`, {
        user_id: +localStorage.getItem('userId'),
        popup_id: popup.id,
        status,
      });

      if (localStorage.getItem('popup'))
        localStorage.removeItem('popup')
      localStorage.setItem('popup', JSON.stringify({ ...data.popup, status:data.status }))
    } catch (error) {
      console.log(error);
      let open = status === 1 ? 'Open' : 'Close';
      notification.error({
        description: `Not able to ${open} Popup`,
      })
    }
    setLoading(false);
  }

  const onClosePopup = async () => {
    await changeStatus(2);
    setModalVisible(false);
  }

  const onVoteNow = async () => {
    setShowCloseBtn(true);
    window.open(popup?.popupLink, "_blank");
    if (!popup.hasOwnProperty('status') && popup.status !== 1)
      await changeStatus(1);

  }

  const onCloseModal = () => {
    setModalVisible(false);
  }

  useEffect(() => {
    let logo;
    let tabTitle;
    const role = localStorage.getItem('rolesUser') || auth?.user?.roles[0];
    if (domain?.domain === undefined) {
      logo = "loading.png";
      tabTitle = "loading..."
    } else {
      if (domain?.domain === DOMAIN.NEX) {
        logo = "nex-icon.png"
        tabTitle = `${title}-Nex Portal`;
      } else {
        logo = "nap-icon.png"
        tabTitle = `${title}-Nap Portal`;
      }
    }
    document.title = tabTitle;
    let iconLink = document.querySelector("link[rel='shortcut icon']")
    iconLink.href = logo;
    if (role !== "ROLE_ADMIN" && window.location.pathname == '/') {
      let url = new URL(window.location.href);
      url.pathname = '/dashboard'
      window.location.href = url.toString();
    }
    if (role !== "ROLE_ADMIN" && window.location.pathname == '/accounting') {
      let url = new URL(window.location.href);
      url.pathname = '/network'
      window.location.href = url.toString();
    }
  }, [title, domain]);


  useEffect(() => {
    console.log("checking...$$$$$$$", popup);
    setModalVisible(popup?.hasOwnProperty('popupImage') && popup?.status !== 2);
  }, [popup]);

  /*if (auth.isAuthenticated) {
    if (!auth.user.roles.includes("ROLE_ADMIN")) {
      return (
        <Result
          status="warning"
          title="You are not authorized to access this page"
        />
      );
    }
  }*/

  return (
    <>
      {popup?.popupImage &&
        <Modal visible={modalVisible}
          footer={null}
          className="vote-modal"
          destroyOnClose={true}
          // width={1000}
        // onCancel={onCloseModal}
        >
          {showCloseBtn ?
            <Button className="close-txt-btn" onClick={onClosePopup} disabled={loading}>
              {popup.closeText}
              {loading && <Icon type="loading" style={{ fontSize: 12 }} spin />}
            </Button> : null}
          <div dangerouslySetInnerHTML={{ __html: popup.popupImage }}
            className="votemodal-wrapper"
            onClick={onVoteNow}
          />
        </Modal>}
      <Route
        {...rest}
        render={props =>
          !localStorage.continueDesktop || localStorage.continueDesktop === "1"
            ?
            auth.isAuthenticated === true
              ?
              (
                <>
                  <Header />
                  <Component {...props} />
                </>
              )
              :
              (
                <Redirect to="/login" />
              )
            :
            (
              <>
                <MobileAlert />
              </>
            )
        }
      />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
