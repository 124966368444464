import React, { Component } from 'react'
import { Button, Collapse, Dropdown, Icon, Menu } from 'antd';
import CustomSelect from 'components/ui-elements/Select';
import CustomTooltip from './CustomTooltip';
import { DOMAIN } from 'utils/enum';
import NetworkItem from './NetworkItem';

const { Panel } = Collapse;
const { Item } = Menu;

export default class NetworkPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            napIdArr: [1, 6, 4],
            headerImages: ['nac-w.svg', 'naf-w.svg', 'naxtc-w.svg',
                // 'nas-w.svg'
            ],
            darkImages: ['nac-b.svg', 'naf-b.svg', 'naxtc-b.svg',
                // 'nas-b.svg'
            ],
            nacGroup: this.props?.subGroup.filter(network => [1, 6, 4].includes(network.id)),
            mainPanelOpen: false,
            // nexGroup: this.props?.subGroup.filter(network => [3, 8].includes(network.id)) 
            //5 need to add in group
            networksLable: {
                2: 'GSSA & Brokerage',
                3: 'eCommerce Logistics ',
                8: 'Academy',
                7: 'Vendors & Solution Providers'
            }
        }
        console.log('checking common states ', true, this.props.common.domain?.domain, DOMAIN.NEX, DOMAIN.STAGE_NEX);
    }

    headerImg(activeImg, id, deactiveImg) {
        return (this.props.selectedSubIndustryId == id || this.props.subIndustryRels == id) ? deactiveImg : activeImg;
    }

    panelHeader({ activeImg, name, expertieses, id, deactiveImg }) {
        return (
            <>
                <CustomTooltip
                    fileStatus="networkSidebar"
                    isDom={true}
                    title={name}
                    subIndustryName={name}
                    expertise={expertieses}
                    position="right"
                    containerClass="tooltipStyleChange"

                >
                    <div className='networkpanel-header'>
                        <img src={this.headerImg.apply(this, [activeImg, id, deactiveImg])} height={40} />
                    </div>
                </CustomTooltip>
            </>
        )
    }

    filterInputs() {
        return (
            <div className='panelfilter-wrapper'>
                <section className="sidebar-section">
                    {/* <h5 className="sidebar-section-title text-white">Location</h5> */}
                    <div className="v-space-1 country-label">
                        {/*   <label className="text-white">Origin country</label> */}
                        <label className="text-white">Country</label>
                        <CustomSelect
                            placeholder={"Country"}
                            value={this.props.country}
                            name="country"
                            options={this.props.countries}
                            isClearable={true}
                            getOptionLabel={(option) => option.countryname}
                            getOptionValue={(option) => option.id}
                            onChange={this.props.handleSelect}
                        ></CustomSelect>
                    </div>
                    <div className="v-space-1">
                        <label className="text-white">City</label>
                        <CustomSelect
                            async={true}
                            value={this.props.city}
                            name="city"
                            isDisabled={!this.props.country}
                            placeholder={"City"}
                            isClearable={true}
                            // cacheOptions
                            loadOptions={this.props.fetchCities}
                            noOptionsMessage={(input) => "No results"}
                            getOptionLabel={(option) => option.cityAsciiname}
                            getOptionValue={(option) => option.id}
                            onChange={this.props.handleSelect}
                        ></CustomSelect>
                    </div>
                </section>
                <div className="v-space-1">
                    <label className="text-white">Industry expertise</label>
                    <CustomSelect
                        placeholder={"Select expertise"}
                        value={this.props.expertiseRels}
                        name="expertiseRels"
                        isSearchable={false}
                        isClearable={true}
                        //options={expertieseListing}
                        options={this.props.expertieseListing.length !== 0 ? this.props.expertieseListing : this.props.expertise}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={this.props.handleSelect}
                    ></CustomSelect>
                </div>
            </div>
        )
    }

    btnGroup(arr) {
        return (
            <div className='network-btn-group'>
                <label className='network-lable'>{this.btngroupLable(arr)}</label>
                {this.props?.subGroup.filter((group) => arr.includes(group.id)).map(group =>
                    <Button key={group.id} onClick={e => { this.props.changeNetwork(group.id) }} className={`network-btn ${(this.props.selectedSubIndustryId == group.id || this.props.subIndustryRels == group.id) ? 'light-network-btn' : 'dark-network-btn'} ${this.props.blockColor(group.id)} Network-parent`}>
                        {this.panelHeader(group)}
                    </Button>
                )}
            </div>
        )
    }

    btngroupLable(arr) {
        if (this.state.napIdArr.every(id => arr.includes(id)))
            return "Air Freight Forwarding"
        else
            if (arr.includes(2))
                return "GSSA & Brokerage"
            else if (arr.includes(7))
                return "Vendors & Solution Providers"
            else if (arr.includes(8))
                return "Academy"
            else
                return "eCommerce Logistics"
    }

    componentDidMount() {
        if ([DOMAIN.NEX, DOMAIN.STAGE_NEX].includes(this.props.common.domain?.domain))
            this.props.changeNetwork(3)
    }

    render() {

        return (
            <div style={{ color: "#ffff" }} className={`${[DOMAIN.NEX, DOMAIN.STAGE_NEX].includes(this.props.common.domain?.domain) ? 'nex-wrapper' : ''}`}>
                {[DOMAIN.NEX, DOMAIN.STAGE_NEX].includes(this.props.common.domain?.domain) ? (<>
                    {this.btnGroup([3])}
                    {this.btnGroup([8])}
                    {this.btnGroup(this.state.napIdArr)}
                    {this.btnGroup([2])}
                    {this.btnGroup([7])}
                </>) : (
                    <>
                        {this.btnGroup(this.state.napIdArr)}
                        {this.btnGroup([2])}
                        {this.btnGroup([3])}
                        {this.btnGroup([7])}
                    </>
                )}
                {this.filterInputs()}
            </div>
        )
    }
}
