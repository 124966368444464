import React, { useState } from "react";
import { Row, Col } from "antd"

import TopAirportsAndAirportsBranches from "./TopData/TopAirportsAndAirportsBranches"
import TopAirlines from "./TopData/TopAirlines"
import TopCountriesAndTradelanes from "./TopData/TopCountriesAndTradelanes"
import importIcon from 'theme/assets/importIconOrange.svg';
import exportIcon from 'theme/assets/exportIcon.svg';

const TopImportExportData = (props) => {
    const { className, title, companyData } = props;
    return (
        <div>
            <div style={{ display: 'flex' }}>
                <span className="import-export-icon">
                    <img src={title === 'EXPORT' ? exportIcon : importIcon} className={`${title === 'EXPORT' ? 'export-icon' : 'import-icon'} section-Icon`} />
                </span>

                <h2 className="import-export-title">{title}</h2>
            </div>
            <div className={`${className}`} ></div>
            <Row className="mt-8 top-countries">
                {/* -----------Top Import and Export Countries----------- */}
                {title === 'EXPORT' ?
                    companyData?.airExportCountry?.length !== 0 &&
                    <Col className="gutter-row topImportExport" lg={24} xl={8}><TopCountriesAndTradelanes title="Top countries" data={companyData?.airExportCountry} /></Col>
                    :
                    companyData?.airImportCountry?.length !== 0 &&
                    <Col className="gutter-row topImportExport" lg={24} xl={8}><TopCountriesAndTradelanes title="Top countries" data={companyData?.airImportCountry} /></Col>
                }
                {/* -----------Top Import and Export Airports----------- */}
                {title === 'EXPORT' ?
                    companyData?.expNacDestAirport?.length !== 0 &&
                    <Col className="gutter-row topImportExport" lg={24} xl={8}><TopAirportsAndAirportsBranches title="Top airports" data={companyData?.expNacDestAirport} /></Col>
                    :
                    companyData?.airImportAirport?.length !== 0 &&
                    <Col className="gutter-row topImportExport" lg={24} xl={8}><TopAirportsAndAirportsBranches title="Top airports" data={companyData?.airImportAirport} /></Col>
                }
                {/* -----------Top Export Airlines----------- */}
                {title === 'EXPORT' &&
                    companyData?.expNacTopAirline?.length !== 0 &&
                    <Col className="gutter-row topImportExport" lg={24} xl={8}><TopAirlines data={companyData?.expNacTopAirline} /></Col>
                }
            </Row>
        </div>
    )
}
export default TopImportExportData 