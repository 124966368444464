import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Skeleton, message } from "antd";
import axios from "axios";
import { base_url, api_v } from "utils/settings";

import { debounce } from "utils/functions";
import { QUESTION_TYPE } from "utils/enum";
import { Dynamic_Fields } from "../../form/DynamicFields";
import InputFieldList from "components/form/InputFieldList";
import { /* getQuestionList, */ getCountriesList, fetchCountries, fetchCities, fetchAirports, fetchAirlines, fetchAirlines2, fetchAirports2, saveAnswers, fetchCitiesByCname } from "actions/questionAction";
import { getQuestionList } from "actions/commonActions";
export default function QuestionsList({
  subIndustry,
  expertises,
  userStatus,
  companyId,
  companyData,
  setAnswerErrors,
  validationErrorMsg,
  setValidationErrorMsg,
  inputFieldData,
  setInputFieldData,
  allQuestionsData,
  setAllQuestionsData,
  fileStatus,
  setNo
}) {
  
  const dispatch = useDispatch();

  const [error, setError] = useState(false)
  const [listing, setlisting] = useState([])
  const [answerId, setAnswerId] = useState([])
  const [loading, setLoading] = useState(true)
  const [answerData, setAnswerData] = useState([])
  // const [fileList, setFileList] = useState([])
  const [inputFields, setInputFields] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const [isEditField, setIsEditField] = useState(false)
  const [selectedOption, setSelectedOption] = useState("")
  const [countryListing, setCountryListing] = useState([])
  const [subIndustriesId, setSubIndustriesId] = useState([])
  // const [inputFieldItemId, setInputFieldItemId] = useState(null)
  const [validationErrorMsgForProfile, setValidationErrorMsgForProfile] = useState({})
  const [filteredQuestionData, setFilteredQuestionData] = useState([])
  const [filterQuestionByQuestionRelations, setFilterQuestionByQuestionRelations] = useState([])

  const state = useSelector((state) => state);
  // console.log("---hi i am render", loading)
  useEffect(() => {
    // console.log(filterQuestionByQuestionRelations,"-----------")
    console.log("useeffect")
    initialFunction()
  }, [])
  //Here, Get all answers from redux and customized data for showing answer field of each question-answer field
  useEffect(() => {
    console.log("company")
    initialFunction()
    if (companyData !== undefined) {
      if (companyData?.expQuizAnswers?.length) {
        let id = []
        let answer = []
        companyData.expQuizAnswers.map((item) => {
          id?.push(item?.question?.id)
          if (item.answer === 'option') {
            if (item.expQuizOptAnswers?.length > 0 && item.multiSelectAnswer?.length === 0) {
              let options = []
              let selectedOptionValue = item.expQuizOptAnswers
              selectedOptionValue.map((option) => options.push((option.options.id).toString()))
              answer.push(options)
            } else {
              let selectedMultiSelectSeachOptionValue = item.multiSelectAnswer
              answer.push(selectedMultiSelectSeachOptionValue)
            }
          } else if (item.answer !== "" && item.question.QuestionType.name === 'DropDown(Multi Select)' && isNaN(item.answer)) {
            let selectedMultiSelectOptionValue = item.answer
            var selectedMultiSelectOptionArray = selectedMultiSelectOptionValue.split(',').map(String);
            let selectedMultiSelectData = selectedMultiSelectOptionArray.map((value) => value.toString())
            answer.push(selectedMultiSelectData)
          }
          else {
            answer.push(item.answer)
          }
        })

        const obj = {};
        id.forEach((element, index) => {
          obj[element] = answer[index];
        });
        setInputFields(obj)
      }
    }
  }, [companyData])

  //Here, Sorting of question based on sequence key
  const sortBySequenceNumber = async (res) => {
    const sortedData = [...res].sort((a, b) => a.sequence - b.sequence);
    return sortedData
    // Update state or perform any further operations with the sortedData
  };
  const Qdata = useSelector(state => state.common.question);
  // Get All Question list
  const initialFunction = async () => {
    if (!Qdata?.length) {
      const res = await dispatch(getQuestionList());
      const countryListResponse = await dispatch(getCountriesList())
      setCountryListing(countryListResponse)
      const sortedList = await sortBySequenceNumber(res)
      setlisting(sortedList)
    } else {
      const sortedList = await sortBySequenceNumber(Qdata)
      setlisting(sortedList)
    }
    setLoading(false)
  }
  //Here, set subIndustries Ids  for user and admin
  useEffect(() => {
    if (subIndustry?.length && userStatus === "admin") {
      let subIndustryId = []
      subIndustry.map((value) => {
        subIndustryId.push(value.id)
      })
      setSubIndustriesId(subIndustryId)
    } else {
      setSubIndustriesId(subIndustry)
    }
  }, [subIndustry])

  useEffect(() => {
    if (listing.length) {
      let filteredQuestionRelationsData = []
      /*  eslint no-unused-expressions: off */
      listing.filter((value) => {
        value.questionRelations?.length
          && filteredQuestionRelationsData.push(value)
      })
      setFilterQuestionByQuestionRelations(filteredQuestionRelationsData)
    }
  }, [listing])

  //Here, Filtered Questions by QuestionRelations(Expertiese and subIndustry)
  useEffect(() => {
    let FinalData = []
    // console.log(filterQuestionByQuestionRelations,"filterQuestionByQuestionRelations")
    filterQuestionByQuestionRelations.map((val1) => {

      let sI = [];
      let eI = []

      if (val1.questionRelations?.length) {
        val1.questionRelations.map((val2) => {
          if (val2.subIndustry !== null) {
            sI.push(val2.subIndustry.id)
          }
          if (val2.expertiese !== null) {
            eI.push(val2.expertiese.id)
          }
        })
      }

      let uniqueSubIndustryId = sI.filter((c, index) => {
        return sI.indexOf(c) === index;
      });
      let uniqueExpertieseId = eI.filter((c, index) => {
        return eI.indexOf(c) === index;
      });

      /*   if (uniqueSubIndustryId?.length && uniqueExpertieseId?.length) {
          let isFoundedSI = uniqueSubIndustryId.some(ai => subIndustriesId.includes(ai));
          let isFoundedEI = uniqueExpertieseId.some(ai => expertises.includes(ai))
          if (isFoundedSI && isFoundedEI) {
            FinalData.push({ ...val1 })
          }
        } else if (uniqueSubIndustryId?.length) {
          let isFoundedSI = uniqueSubIndustryId.some(ai => subIndustriesId.includes(ai));
          if (isFoundedSI) {
            FinalData.push({ ...val1 })
          }
        } else if (uniqueExpertieseId?.length) {
          let isFoundedEI = uniqueExpertieseId.some(ai => expertises.includes(ai))
          if (isFoundedEI) {
            FinalData.push({ ...val1 })
          }
        } */

      let isFoundedSI = uniqueSubIndustryId.some(ai => subIndustriesId.includes(ai));
      let isFoundedEI = uniqueExpertieseId.some(ai => expertises.includes(ai))
      /*   console.log("isFoundedSI", isFoundedSI, val1)
        console.log("isFoundedEI", isFoundedEI, val1) */

      if (isFoundedSI || isFoundedEI) {
        FinalData.push({ ...val1 })
      }

      setFilteredQuestionData(FinalData)

      const filteredActiveQuestions = FinalData.filter((element) => element.active === true) //Here, filter data For Required validation of empty question

      setAllQuestionsData(filteredActiveQuestions)
      return FinalData
    })

  }, [filterQuestionByQuestionRelations])

  //Here, Customization question-anwers data for api
  useEffect(() => {
    setInputFieldData(inputFields)
    const splitKeyValue = obj => {
      const keys = Object.keys(obj);
      const res = [];
      for (let i = 0; i < keys?.length; i++) {
        if (obj[keys[i]]?.length !== 0 && typeof obj[keys[i]] !== 'string' && typeof obj[keys[i]] !== 'number') {
          const optionValue = []
          let selectedValueOfMultiSelect;
          let selectedValueOfMultiSelectSeach;
          let selectedValueOfSingleSelectSearch;
          // console.log(" obj[keys[i]]", Object.keys(obj[keys[i]])[0] === 'airline', obj[keys[i]])
          if (obj[keys[i]].length > 0 && Object.keys(obj[keys[i]])[0] !== 'airline' && Object.keys(obj[keys[i]])[0] !== 'airport') {
            // console.log("hellog iffff")
            obj[keys[i]]?.map((value) => {
              if (isNaN(value) && typeof value === "string") {
                // console.log("1...............")
                selectedValueOfMultiSelect = String(obj[keys[i]])
              } else if (isNaN(value) && typeof value === 'object') {
                selectedValueOfMultiSelectSeach = obj[keys[i]]
              } else {
                // console.log("3...............")
                optionValue.push({
                  "options": `/v1/question_options/${value}`,
                  "answer": "",
                  "question": `/v1/questions/${keys[i]}`,
                  "company": `/v1/companies/${companyId}`
                })
              }
            }
            )
          } else if (Object.keys(obj[keys[i]])[0] === 'airline') {
            selectedValueOfSingleSelectSearch = obj[keys[i]]?.airline?.iata
          } else {
            selectedValueOfSingleSelectSearch = obj[keys[i]]?.airport?.iata
          }

          if (selectedValueOfMultiSelect) {
            res.push({
              "answer": selectedValueOfMultiSelect,
              "question": `/v1/questions/${keys[i]}`,
              "company": `/v1/companies/${companyId}`
            })
          } else if (selectedValueOfMultiSelectSeach) {
            // console.log("else 1..")
            res.push({
              "answer": "option",
              "multiSelectAnswer": selectedValueOfMultiSelectSeach,
              "question": `/v1/questions/${keys[i]}`,
              "company": `/v1/companies/${companyId}`
            })
          } else if (selectedValueOfSingleSelectSearch) {
            res.push({
              "answer": selectedValueOfSingleSelectSearch,
              "question": `/v1/questions/${keys[i]}`,
              "company": `/v1/companies/${companyId}`,
            })
          }
          else {
            res.push({
              "answer": 'option',
              "question": `/v1/questions/${keys[i]}`,
              "company": `/v1/companies/${companyId}`,
              "expQuizOptAnswers": optionValue
            })
          }
        } else {
          res.push({
            "answer": obj[keys[i]]?.toString(),
            "question": `/v1/questions/${keys[i]}`,
            "company": `/v1/companies/${companyId}`
          });
        }
      };
      return res;
    };
    const expQuizAnswers = splitKeyValue(inputFields);
    const data = {
      "expQuizAnswers": expQuizAnswers
    }
    if (isEditField) {
      handleSaveAnswers(data)
    }
  }, [inputFields])

  //Here, Integrate SaveAnswer api
  const handleSaveAnswers = useCallback(
    debounce(data => {
      if (data.expQuizAnswers?.length > 0) {
        setAnswerData(data.expQuizAnswers)
        // dispatch(saveAnswers(data, companyId/* ? companyId :state.company.data.id */))
        dispatch(saveAnswers(data, companyId ? companyId : state.company.data.id))
        message.success("Answer save successfully");
      }
      setIsEditField(false)
    }, 1000),
    []
  );

  //Here, Show toast message in case of max value validate
  const handleToastErrorMessage = (value) => {
    message.error(`Select only ${value} items.`);
  }

  //Here, set answers/updated value for all question's field after pass validation
  const handleInputFields = (event, inputIdentifier, value, name) => {
    let eventValue;
    if (event?.target?.type !== undefined || event !== null) {
      if (event?.target?.type === 'radio') {
        eventValue = event?.target?.value
      }
      else if (event?.target?.type === 'text' || event?.target?.type === 'textarea' || event?.target?.type === 'password') {
        event.persist()
        eventValue = event?.target?.value
      } else if (event?.length > 0 && typeof event === 'object' && value === undefined) {
        const id = event?.map((value) => value.id ? value?.id : value)
        eventValue = id
      }
      else if (event?.length > 0 && typeof event === 'object' && value.length > 0) {
        const id = event?.map((value) => value)
        eventValue = id
      } else if (event?.length !== 0 && typeof event === 'object' && value.name === 'multiSelectSearch') {
        eventValue = event;
      }
      /*   else if (event?.file) {        // Implement in future
          if (event.file.status === "removed") {
            eventValue = ''
          } else {
            eventValue = event.file.name
          }
        }  */
      else if (name === "singleselect") {
        eventValue = value?.props?.children
      }
      else if (event.value) {
        setSelectedOption(event.value)
        if (event.airportName) {
          eventValue = event.airportName
        } else if (event.airlineName) {
          eventValue = event.airlineName
        } else if (event.countryname) {
          eventValue = event.countryname
        } else {
          // if(event.countryname){
          //   eventValue = event.id
          // }else{
          eventValue = event.value
          // }
        }
      } else if (name === "date") {
        eventValue = value;
      }
      else {
        eventValue = event?.id ? event?.id : event
      }

      setInputFields((prevValue) => {
        return {
          ...prevValue,
          [inputIdentifier]: eventValue
        }
      })
    } else {
      setInputFields((prevValue) => {
        return {
          ...prevValue,
          [inputIdentifier]: []
        }
      })
    }
  }

  const compareEventValueWithMinMax = (eventValue, minValue, maxValue, inputIdentifier, event, name, value, fieldName = "notNumberFormat") => {
    if (fieldName === "numberFormat" || fieldName === "numberAndPercentageSlider") {
      if (eventValue < minValue) {
        setValidationErrorWithMin(minValue, inputIdentifier)
        handleInputFields(event, inputIdentifier, value, name)
      } else {
        if (maxValue !== null && eventValue > maxValue) {
          setError(true)
          setValidationErrorBetweenMinMax(inputIdentifier)
          handleToastErrorMessage(maxValue)
        } else {
          setValidationErrorBetweenMinMax(inputIdentifier)
          handleInputFields(event, inputIdentifier, value)
        }
      }
    } else {
      if (eventValue?.length < minValue && eventValue?.length !== minValue && eventValue?.length !== 0) {
        setValidationErrorWithMin(minValue, inputIdentifier)
        handleInputFields(event, inputIdentifier, value, name)
      } else if (eventValue?.length === minValue) {
        setValidationErrorBetweenMinMax(inputIdentifier)
        handleInputFields(event, inputIdentifier, value)
      }
      else {
        if (maxValue !== null && eventValue?.length > maxValue) {
          setError(true)
          handleToastErrorMessage(maxValue) //set validation error in case of max 
        } else {
          setValidationErrorBetweenMinMax(inputIdentifier)
          handleInputFields(event, inputIdentifier, value)
        }
      }
    }
  }

  const setValidationErrorWithMin = (value, id) => {
    if (fileStatus !== "expertise") {
      setValidationErrorMsg((prevValue) => {
        return {
          ...prevValue,
          [id]: {
            'error': `Select minimum ${value} items.`,
            'id': id,
            'msg': "min/max"
          }
        }
      })
    } else {
      setValidationErrorMsgForProfile((prevValue) => {
        return {
          ...prevValue,
          [id]: {
            'error': `Select minimum ${value} items.`,
            'id': id,

          }
        }
      })
    }
  }

  const setValidationErrorBetweenMinMax = (id) => {
    if (fileStatus !== "expertise") {
      setValidationErrorMsg((prevValue) => {
        return {
          ...prevValue,
          [id]: {
            'error': ``,
            'id': id,
            'msg': "min/max"
          }
        }
      })
    } else {
      setValidationErrorMsgForProfile((prevValue) => {
        return {
          ...prevValue,
          [id]: {
            'error': ``,
            'id': id
          }
        }
      })
    }
  }

  //Here, Handle onChange answer's field 
  const inputChangedHandler = (event, inputIdentifier, value, name) => {
    setIsEditField(true)
    // inputIdentifier has Field's id in which field changes
    const answerChangeData = filteredQuestionData.filter((value) => value.id === inputIdentifier && value) // here, filter data in which field has changed
    if (answerChangeData?.length !== 0) {

      let minValue = answerChangeData[0].minLimit
      let maxValue = answerChangeData[0].maxLimit

      let dateTime = name === 'date'
      let singleSelect = name !== "singleselect" && name !== "singleSelectSearch"
      let multiSelectCheckbox = value?.name === "multiSelectSearch" || name === "multiselect" || name === "checkbox"
      let allInputType = event?.target?.name === "email" || event?.target?.name === "textbox" || event?.target?.name === "phoneNumber" || event?.target?.name === "textarea" || event?.target?.name === "password" || event?.target?.name === "numberFormat"
      /* ------- Here, Check min/max validataion on Change answer's field ------ */
      /* -------------here, handle min/max validataion if min/max (both or either not equal to null)------------- */
      if ((minValue !== null && maxValue !== null) || (minValue === null && maxValue !== null) || (minValue !== null && maxValue == null)) {
        if (singleSelect) {
          if (multiSelectCheckbox) {
            if (fileStatus === "expertise") {
              if (event?.length === 0 || event === null || event === undefined || event === '') {
                setValidationErrorMsgForProfile((prevValue) => {
                  return {
                    ...prevValue,
                    [inputIdentifier]: {
                      'error': "Field is required",
                      'id': inputIdentifier
                    }
                  }
                })
                handleInputFields(event, inputIdentifier, value, name)
              } else {
                if (event?.includes("Pharma") || event?.includes("Medical Science")) {
                  const linkedData = filteredQuestionData.filter((element) => element.linkedQuestion === inputIdentifier)
                  let qId = []
                  answerData.map((item) => qId.push(Number(item?.question?.slice(14))))
                  linkedData.map((ele) => {
                    if (qId.includes(ele.id)) {
                      setValidationErrorMsgForProfile((prevValue) => {
                        return {
                          ...prevValue,
                          [ele.id]: {
                            'error': "",
                            'id': ele.id
                          }
                        }
                      })
                    } else {
                      setValidationErrorMsgForProfile((prevValue) => {
                        return {
                          ...prevValue,
                          [ele.id]: {
                            'error': "Field is required",
                            'id': ele.id
                          }
                        }
                      })
                    }
                  }
                  )
                  compareEventValueWithMinMax(event, minValue, maxValue, inputIdentifier, event, name, value)
                } else {
                  compareEventValueWithMinMax(event, minValue, maxValue, inputIdentifier, event, name, value)
                }
              }
            } else {
              compareEventValueWithMinMax(event, minValue, maxValue, inputIdentifier, event, name, value)
            }
          } else if (allInputType) {
            let eventValue = event?.target?.name === "numberFormat" ? Number((event?.target?.value).slice(1)) : event?.target?.value
            if (typeof eventValue === 'string') {
              if (fileStatus === "expertise") {
                if (eventValue === '') {
                  setValidationErrorMsgForProfile((prevValue) => {
                    return {
                      ...prevValue,
                      [inputIdentifier]: {
                        'error': "Field is required",
                        'id': inputIdentifier
                      }
                    }
                  })
                  handleInputFields(event, inputIdentifier, value, name)
                } else {
                  compareEventValueWithMinMax(eventValue, minValue, maxValue, inputIdentifier, event, name, value)
                }
              } else {
                compareEventValueWithMinMax(eventValue, minValue, maxValue, inputIdentifier, event, name, value)
              }

            } else { // Here, validate NumberFormat
              if (fileStatus === "expertise") {
                if (eventValue === '') {
                  setValidationErrorMsgForProfile((prevValue) => {
                    return {
                      ...prevValue,
                      [inputIdentifier]: {
                        'error': "Field is required",
                        'id': inputIdentifier
                      }
                    }
                  })
                  handleInputFields(event, inputIdentifier, value, name)
                } else {
                  compareEventValueWithMinMax(eventValue, minValue, maxValue, inputIdentifier, event, name, value, "numberFormat")
                }
              } else {
                compareEventValueWithMinMax(eventValue, minValue, maxValue, inputIdentifier, event, name, value, "numberFormat")
              }
            }
          }
          else if (name === "number" || name === "percentageSlider") {
            if (fileStatus === "expertise") {
              if ((event === '' || event === 0) && (minValue === null && maxValue !== null)) {
                setValidationErrorMsgForProfile((prevValue) => {
                  return {
                    ...prevValue,
                    [inputIdentifier]: {
                      'error': "Field is required",
                      'id': inputIdentifier
                    }
                  }
                })
                handleInputFields(event, inputIdentifier, value, name)
              } else {
                compareEventValueWithMinMax(event, minValue, maxValue, inputIdentifier, event, name, value, "numberAndPercentageSlider")
              }
            } else {
              compareEventValueWithMinMax(event, minValue, maxValue, inputIdentifier, event, name, value, "numberAndPercentageSlider")
            }
          }
        } else {
          // here, handle min/max validation for singleSelect and singleSelectSearch for "EDIT PROFILE"
          if (fileStatus === "expertise") {
            if (event === '') {
              setValidationErrorMsgForProfile((prevValue) => {
                return {
                  ...prevValue,
                  [inputIdentifier]: {
                    'error': "Field is required",
                    'id': inputIdentifier
                  }
                }
              })
              handleInputFields(event, inputIdentifier, value, name)
            } else {
              setValidationErrorBetweenMinMax(inputIdentifier)
              handleInputFields(event, inputIdentifier, value, name)
            }
          } else { // here, handle min/max validation for singleSelect and singleSelectSearch for "REGISTRATION"
            setValidationErrorBetweenMinMax(inputIdentifier)
            handleInputFields(event, inputIdentifier, value, name)
          }
        }
      }
      /* ----------------- here, handle when min/max value is null --------------------- */
      else {
        /* -------------here, handle required validation only for "EDIT PROFILE" when min/max is null and field is empty on change---------------- */
        if (fileStatus === "expertise") {
          if (multiSelectCheckbox) { // handle required error in case of multiSelectSearch, multiselect and checkbox           
            if (event !== null && event.length !== 0) {
              setValidationErrorBetweenMinMax(inputIdentifier)
            } else {
              setValidationErrorMsgForProfile((prevValue) => {
                return {
                  ...prevValue,
                  [inputIdentifier]: {
                    'error': "Field is required",
                    'id': inputIdentifier
                  }
                }
              })
            }
          } else if (name === "singleselect" || name === "singleSelectSearch") { // handle required error in case of singleSelectSearch and singleSelect            
            if (event) {
              setValidationErrorBetweenMinMax(inputIdentifier)
            } else {
              setValidationErrorMsgForProfile((prevValue) => {
                return {
                  ...prevValue,
                  [inputIdentifier]: {
                    'error': "Field is required",
                    'id': inputIdentifier
                  }
                }
              })
            }
          } else if (allInputType) {
            if (event?.target?.value !== '' && event?.target?.value !== null && event?.target?.value !== undefined && event?.target?.value.length !== 0) {
              setValidationErrorBetweenMinMax(inputIdentifier)
            } else {
              setValidationErrorMsgForProfile((prevValue) => {
                return {
                  ...prevValue,
                  [inputIdentifier]: {
                    'error': "Field is required",
                    'id': inputIdentifier
                  }
                }
              })
            }
          } else if (name === 'number') {
            if (event) {
              setValidationErrorBetweenMinMax(inputIdentifier)
            } else {
              setValidationErrorMsgForProfile((prevValue) => {
                return {
                  ...prevValue,
                  [inputIdentifier]: {
                    'error': "Field is required",
                    'id': inputIdentifier
                  }
                }
              })
            }
          } else if (dateTime) {  // handle required error in case of date, dateTime and time
            if (value) {
              setValidationErrorBetweenMinMax(inputIdentifier)
            } else {
              setValidationErrorMsgForProfile((prevValue) => {
                return {
                  ...prevValue,
                  [inputIdentifier]: {
                    'error': "Field is required",
                    'id': inputIdentifier
                  }
                }
              })
            }
          } else if (name === "percentageSlider") {  // handle required error in case of only percentage slider
            if (event !== 0) {
              setValidationErrorBetweenMinMax(inputIdentifier)
            } else {
              setValidationErrorMsgForProfile((prevValue) => {
                return {
                  ...prevValue,
                  [inputIdentifier]: {
                    'error': "Field is required",
                    'id': inputIdentifier
                  }
                }
              })
            }
          }
          handleInputFields(event, inputIdentifier, value, name)
        } else {
          /* --------------- here, handle error for "REGISTRATION" if min/max value is null ---------------- */
          setValidationErrorBetweenMinMax(inputIdentifier)
          handleInputFields(event, inputIdentifier, value, name)
        }
      }
    }
  }

  //Here, handle dynamically min/max validatiaon error 
  useEffect(() => {
    if (validationErrorMsg !== undefined) {
      let keys = Object.keys(validationErrorMsg)
      setAnswerId(keys)
      setAnswerErrors(validationErrorMsg)
    } else {
      let keys = Object.keys(validationErrorMsgForProfile)
      setAnswerId(keys)
    }
  }, [validationErrorMsg, validationErrorMsgForProfile])

  /*  const handleMultiFileUpload = (info) => {
     let newFileList = [...info.fileList];
   
     // 1. Limit the number of uploaded files
     // Only to show two recent uploaded files, and old ones will be replaced by the new
     newFileList = newFileList
   
     // 2. Read from response and show file link
     newFileList = newFileList.map((file) => {
       if (file.response) {
         // Component will show file.url as link
         file.url = file.response.url;
       }
       return file;
     });
   
     setFileList(newFileList);
   }
  */

  const formatAirlineOption = ({ airline }, { context }) => {
    if (airline === undefined) {
      return null;
    }
    if (context === "menu") {
      return (
        <div className="border-bottom">
          <div>
            {airline.airlineName} ({airline.iata})
          </div>
          <span className="text-secondary">{airline.country}</span>
        </div>
      );
    }
    return <div>{airline.iata}</div>;
  };

  const formatAirportOption = ({ airport }, { context }) => {
    if (airport === undefined) {
      return null;
    }
    if (context === "menu") {
      return (
        <div className="border-bottom">
          <div>
            {airport.airportName} ({airport.iata})
          </div>
          <span className="text-secondary">{airport.country}</span>
        </div>
      );
    }
    return <div>{airport.iata}</div>;
  };
  // console.log(filteredQuestionData,"-filteredQuestionData")
  return (
    <div>
      {
        loading
          ?
          <Skeleton active />
          :
          filteredQuestionData?.map((item, index) => {
            let fieldStatus = ''
            let itemNew = []
            let linkedValue;
            let changed = () => { }
            let loadOptions = () => { }
            let getOptionLabel = () => { }
            let getOptionValue = () => { }
            let formatOptionLabel = () => { }
            let formatGroupLabel = () => { }

            if (item.callAction != "") {
              let disable;
              if (item.callAction != "" && item.linkedQuestion != "") {
                const getque = filteredQuestionData.filter(e => e.id == item.linkedQuestion)
                if (item?.QuestionType === "/v1/question_types/13" && getque?.[0]?.QuestionType === "/v1/question_types/9") {
                  linkedValue = item.linkedQuestion
                  let ans = inputFields?.[linkedValue]
                  const dd = getque[0]?.questionOptions?.filter(e => e.id == ans)
                  const dd2 = dd?.[0]?.label.toLowerCase()
                  disable = (dd2 === undefined || dd2 === "no") ? true : false
                  itemNew = {
                    ...item,
                    [`disable`]: disable
                  }

                  if (dd2 === undefined || dd2 === "no") {

                    setNo(true)
                  } else {

                    setNo(false)
                  }
                }
              }


              let [, , , questionTypeNumber] = item.QuestionType.split("/");
              if (item.callAction === "CountryList" && (QUESTION_TYPE[questionTypeNumber] === 'CHECKBOX' || QUESTION_TYPE[questionTypeNumber] === 'SINGLE_SELECT' || QUESTION_TYPE[questionTypeNumber] === 'MULTI_SELECT')) {

                changed = (value) => {
                  // dispatch(setSelectedCountry(value))
                }
                itemNew = {
                  ...item,
                  // [`questionOptions`]: state.data.countries,
                  [`questionOptions`]: countryListing,
                  [`changed`]: changed,
                  [`disable`]: disable ? disable : false,
                }
              } else if (item.callAction === "CountryList" && (QUESTION_TYPE[questionTypeNumber] === 'SINGLE_SELECT_SEARCH' || QUESTION_TYPE[questionTypeNumber] === 'MULTI_SELECT_SEARCH')) {
                loadOptions = (input, cb) => fetchCountries(input, cb)
                getOptionLabel = option => option.countryname ? option.countryname : ''
                getOptionValue = option => option.id ? option.id : ''
                itemNew = {
                  ...item,
                  [`questionOptions`]: state.data.countries,
                  [`disable`]: disable ? disable : false,
                  // [`changed`]: changed,
                }
              }
              else if (item.callAction === "CityList") {
                if (item.linkedQuestion) {
                  linkedValue = item.linkedQuestion
                }
                // console.log("linkV",inputFields)
                loadOptions = (input, cb) => fetchCitiesByCname(input, inputFields?.[linkedValue], cb)
                getOptionLabel = option => option.cityAsciiname ? option.cityAsciiname : ''
                getOptionValue = option => option.id ? option.id : ''
                itemNew = {
                  ...item,
                  // [`questionOptions`]: state.data.countries
                  [`questionOptions`]: [],
                  [`disable`]: disable ? disable : false,
                }
              }
              else if (item.callAction === "AirportsList") { // Pending functionality     
                if (item.linkedQuestion) {
                  linkedValue = item.linkedQuestion
                }
                /*  loadOptions = (input, cb) => fetchAirports(input, cb) */
                loadOptions = (input, cb) => fetchAirports2(input, cb)
                fieldStatus = "airport"
                formatOptionLabel = formatAirportOption
                getOptionLabel = (option) => {
                  return option.iata
                }
                getOptionValue = (option) => {
                  return option?.airport?.id
                }
                /*     getOptionLabel = option => option.airportName
                    getOptionValue = option => option.id */
                itemNew = {
                  ...item,
                  [`questionOptions`]: [],
                  [`disable`]: disable ? disable : false,
                }
              }

              else if (item.callAction === "AirlinesList") { // Pending functionality
                loadOptions = (input, cb) => fetchAirlines2(input, cb)

                /* getOptionLabel = option => option.airlineName
                getOptionValue = option => option.id */
                fieldStatus = "airline"
                formatOptionLabel = formatAirlineOption
                // formatGroupLabel = formatAirlineOption
                getOptionLabel = (option) => {
                  return option.iata
                }
                getOptionValue = (option) => {
                  return option?.airline?.id
                }
                itemNew = {
                  ...item,
                  [`questionOptions`]: [],
                  [`disable`]: disable ? disable : false,
                }
              }
              // this block is creted for static handling linking
            } else if (item.callAction === "" && item.linkedQuestion != "") {
              let disable;
              const getque = filteredQuestionData.filter(e => e.id == item.linkedQuestion)
              if (item?.QuestionType === "/v1/question_types/9" && getque?.[0]?.QuestionType === "/v1/question_types/12") {
                linkedValue = item.linkedQuestion
                let ans = inputFields?.[linkedValue]
                const patternPharma = /\bpharma\b/i;
                const patternMedicalScience = /\bmedical[\s-]+science\b/i;
                const hasPharma = ans?.length > 0 && ans?.some((value) => patternPharma.test(value));
                const hasMedicalScience = ans?.length > 0 && ans?.some((value) => patternMedicalScience.test(value));

                if (hasPharma || hasMedicalScience) {
                  disable = false
                } else {
                  disable = true
                }
              }
              if (item?.QuestionType === "/v1/question_types/1" && getque?.[0]?.QuestionType === "/v1/question_types/9") {

                linkedValue = item.linkedQuestion
                let ans = inputFields?.[linkedValue]
                const dd = getque[0]?.questionOptions?.filter(e => e.id == ans)
                const dd2 = dd?.[0]?.label.toLowerCase();
                disable = (dd2 === undefined || dd2 === "no") ? true : false
                if (dd2 === undefined || dd2 === "no") {
                  setNo(true)
                } else {
                  setNo(false)
                }
              }
              if (item?.QuestionType === "/v1/question_types/10" && getque?.[0]?.QuestionType === "/v1/question_types/9") {
                linkedValue = item.linkedQuestion
                let ans = inputFields?.[linkedValue]
                const dd = getque[0]?.questionOptions?.filter(e => e.id == ans)
                const dd2 = dd?.[0]?.label.toLowerCase()
                disable = (dd2 === undefined || dd2 === "no") ? true : false
                if (dd2 === undefined || dd2 === "no") {
                  setNo(true)
                } else {
                  setNo(false)
                }
              }
              itemNew = {
                ...item,
                [`disable`]: disable
              }
            }
            else {
              itemNew = {
                ...item,
                [`disable`]: false
              }
            }

            let questionItem = { ...Dynamic_Fields(itemNew,expertises) }
            const [, , , number] = item.QuestionType.split("/");

            // let AnsValue;

            // if(item.callAction==="AirlinesList"){
            //   AnsValue=inputFields?.[item.id]
            //   AnsValue=inputFields?.[item.id]?.map((element, index) => {
            //     delete element.value;
            //     element.value=element?.iata
            //     return element;
            // //   arr[index] = element + 10;
            // });
            // }else{
            //   AnsValue=inputFields?.[item.id]
            // }



            if (item.active) {

              return (
                <Row key={index}>
                  {/* <Col span={3}></Col> */}
                  {/* <Col span={1}>{item.id}</Col> */}
                  <Col span={24}>
                    <div>
                      <InputFieldList
                        fileStatus={fileStatus}
                        key={`field_${item.id}`}
                        isradio={questionItem.isradio == 1 ? 1 : 0}
                        elementType={questionItem.elementType}
                        elementConfig={questionItem.elementConfig}
                        col={questionItem.col}
                        label={questionItem.label}
                        value={inputFields?.[item.id]}
                        invalid={!questionItem.valid}
                        shouldValidate={questionItem.validation}
                        touched={questionItem.touched}
                        fullRow={questionItem.fullRow}
                        heading={questionItem.heading}
                        divider={questionItem.divider}
                        disable={questionItem.disable}
                        // changed={questionItem.changed}
                        changed={(event, value, name) => inputChangedHandler(event, item.id, value, name)}
                        loadOptions={loadOptions}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        fieldStatus={fieldStatus}
                        formatOptionLabel={formatOptionLabel}
                        // formatGroupLabel={formatGroupLabel}

                        leftText={questionItem.leftText}
                        // error={questionItem.error}
                        maxLimit={questionItem.maxLimit}
                        minLimit={questionItem.minLimit}
                        selectedOption={selectedOption}
                        error={(answerId.length &&
                          answerId.map((value) => Number(value) === item.id
                            &&
                            (validationErrorMsg !== undefined ? validationErrorMsg[value].error : validationErrorMsgForProfile[value].error)))}
                      /*   error={(((error || errorMessage !== '') && inputFieldItemId === item.id)
                          && errorMessage)} */
                      //   handleMultiFileUpload={(event) => handleMultiFileUpload(event)}
                      />
                    </div>
                  </Col>
                  <Col span={4}></Col>
                </Row>
              )
            }

          })
      }
      {/*   </Form>
              </>
            )}
          </AutoSaveWrapper> */}
    </div>
  );
}
