import React, { Component } from "react";
import { Col, Tabs } from "antd";
import { airPartnerTabs, associateTabs, vendorTabs } from './TabNames';
import Overview from "./overview/Overview";
import Company from "./company/Company";
import Personnel from "./personnel/Personnel";
import RatesFees from './rates&fees/Rates&Fees';
import VendorCompany from "./company/VendorCompany";
import Activity from './activity/Activity'
const { TabPane } = Tabs;

const components = {
	overview: Overview,
	company: Company,
	personnel: Personnel,
	ratesFees: RatesFees,
	activity: Activity,
	vendorCompany: VendorCompany
}

class LeftPane extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ids:this.props.company?.subIndustry.map(({ id }) => id)
		}
	}

	render() {
		const filterTabs = (tabList) => {
			// let _tabList = tabList;

			// const companyInfo = JSON.parse(localStorage.companyInfo);
			// const { keyPersonel } = companyInfo;
			// console.log('keyPersonel',keyPersonel);
			// console.log('tabList',tabList);
			// if (!keyPersonel) {
			// 	_tabList = tabList.filter(t => t.key !== "personnel");
			// }
			// console.log('_tabList',_tabList);
			// return _tabList;
			return tabList;
		}

		const tabNames = () => {
			console.log("checking...things....new#####",this.state.ids)
			// if (this.props.company?.subIndustry?.filter(item => item.id === 7)?.length !== 0) {
			// 	return vendorTabs
			// } else {
			// 	return airPartnerTabs;
			// }
			if(this.state.ids.length == 1 && this.state.ids.includes(7)) 
				 return vendorTabs;
			else  return airPartnerTabs;

			/* 
			switch (this.props.company.companySettings[0].classification) {
				case 'Associate':
					return associateTabs;
				case 'Vendor':
					return vendorTabs;
				default:
					return airPartnerTabs;
			} */
		}

		function componentName(name, company, auth) {
			const SpecificComponent = components[name];
			return <SpecificComponent company={company} auth={auth} />;
		}

		return (
			<Col span={24} className="bg-white">
				<Tabs className="edit-profile-tab" tabBarStyle={{ padding: '0 15px' }} onChange={(activeKey) => this.props.setActiveTab(activeKey)}>
					{filterTabs(tabNames()).map(tab => (
						<TabPane className={this.props.company?.subIndustry?.filter(item => item.id === 7)?.length !== 0 ? "vendor-leftpane-tab-content" : "leftpane-tab-content"}
							tab={<span>{tab.icon}{tab.name}</span>}
							key={tab.name}
						>
							{componentName(tab.key, this.props.company, this.props.auth)}
						</TabPane>
					))}
				</Tabs>
			</Col>
		)
	}
}

export default LeftPane;
