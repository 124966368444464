import { Menu } from 'antd';
import React, { Component } from 'react'

const { Item } = Menu

export default class NetworkItem extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return <Menu defaultSelectedKeys={[1]} >
            {this.props.nacGroup.map(({ id, deactiveImg, expertieses, name }) =>
                <>
                    {/* <CustomTooltip
                    fileStatus="networkSidebar"
                    isDom={true}
                    title={name}
                    subIndustryName={name}
                    expertise={expertieses}
                    position="right"
                    containerClass="tooltipStyleChange"

                > */}
                    <Item key={id} {...this.props}>
                        <div className='item-img-wrapper'>
                            <img src={deactiveImg} alt={`${id}-images`} height={40} style={{ objectFit: "scale-down", aspectRatio: "3/2 auto" }} />
                        </div>
                    </Item>
                    {/* </CustomTooltip> */}
                    <Menu.Divider />
                </>
            )}
        </Menu>
    }
}
