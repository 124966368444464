import React, { useState, useEffect } from 'react'
import { Checkbox, Col, Row, Skeleton } from 'antd';
import { getSubGroupList } from '../../../actions/commonActions';
import { useSelector, useDispatch } from 'react-redux';
// import { updateCompany, addSubGroupCompany, getCompany } from "actions/companyActions";
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
import { message } from 'antd';
import {
    getCompany,
    updateCompany,
    updateSelectedExpertises,
    updateSelectedExpertisesV2
} from "actions/companyActions";
import { findExpertise, findExpertiseV2, findExpertiseV3, formatSelectedExpertisesV2 } from "utils/functions";
import SubIndustriesExpertiese from "../../common/subIndustriesExpertiese1"
import { debounce } from "lodash";
import { ConsoleSqlOutlined } from '@ant-design/icons';

const SubGroup = (props) => {
    const [selectedSubGroupId, setSelectedSubGroupId] = useState([])
    const [loading, setLoading] = useState(true)
    // SubIndustries and expertiese
    const [subIndustries, setSubIndustries] = useState([])
    const [checkedValObj, setCheckedValObj] = useState([])
    const [checkedValueStatus, setCheckedValueStatus] = useState(false)
    const [selectedExpertieseIds, setSelectedExpertieseIds] = useState([])
    const [allSelectedExpertiese, setAllSelectedExpertiese] = useState({})
    const [selectedSubIndustryIds, setSelectedSubIndustryIds] = useState([])
    const [allSelectedSubIndustries, setAllSelectedSubIndustries] = useState({})
    const [selectedExpertieseIdsArray, setSelectedExpertieseIdsArray] = useState([])

    const subGroup = useSelector(state => state.company.data?.subIndustry)
    const companyData = useSelector(state => state.company.data)
    const companyId = useSelector(state => state.company.data?.id)
    const dispatch = useDispatch();

 
    useEffect(() => {
        initialFunction()
    }, [])

    useEffect(() => {
        
        if (subGroup) {
            const idArray = subGroup.map(obj => obj.id);
            setSelectedSubGroupId(idArray)
        }
    }, [subGroup])

    const initialFunction = async () => {
        const response = await dispatch(getSubGroupList());
        setSubIndustries(response)
        setLoading(false);
    }

    const handleSubIndustry = async (id) => {

        /* added this logic for delete expertise which is under the network which is  unselected, so that when we change expertise. only checked values submit the expertise which is network slecetd */
        // const objectExp = checkedValObj;
        // const notSelectedNames = subIndustries
        //     .filter(item => !id.includes(item.id))
        //     .map(item => item.name);
        // for (const name of notSelectedNames) {
        //     if (objectExp.hasOwnProperty(name)) {
        //         delete objectExp[name];
        //     }
        // }
        
        // setCheckedValObj(objectExp)
        /* added this logic for delete expertise which is under the network which is  unselected, so that when we change expertise. only checked values submit the expertise which is network slecetd */
        setSelectedSubGroupId(id)
        if (id) {
            let params = {
                "subIndustry": []
            }
            params["subIndustry"] = id.map((element) => "/v1/sub_industries/" + element);
            await dispatch(updateCompany(params, companyId));
            props.makeRerender()
            // props.getCompany(550)
            
            message.success("The information has been updated");
        }
    }
    // get sub-industries and expertiese from redux and set in state array
    useEffect(() => {
        if (companyData.subIndustry) { // Here, selectedSubIndustryIds conatain in an state array which comes from redux
            const subIndustryIdsArray = companyData.subIndustry.map(obj => obj.id);
            setSelectedSubIndustryIds(subIndustryIdsArray)
        }
        if (companyData.expertiese) { // Here, set expertise ids in stateArray which comes from redux
            const expertieseIdsArray = companyData.expertiese.map(obj => obj.id);
            setSelectedExpertieseIdsArray(expertieseIdsArray)
        }
        const expertisestoCheckedValues = findExpertiseV3(companyData)
        /* added this logic for delete expertise which is under the network which is  unselected, so that when we change expertise. only checked values submit the expertise which is network slecetd */
        // const objectExp = expertisestoCheckedValues;
        // const subIndustryIdsArray = companyData.subIndustry.map(obj => obj.id);
        // const notSelectedNames = subIndustries
        //     .filter(item => !subIndustryIdsArray.includes(item.id))
        //     .map(item => item.name);
        // for (const name of notSelectedNames) {
        //     if (objectExp.hasOwnProperty(name)) {
        //         delete objectExp[name];
        //     }
        // }
        
        // setCheckedValObj(objectExp)
        /* added this logic for delete expertise which is under the network which is  unselected, so that when we change expertise. only checked values submit the expertise which is network slecetd */
        setCheckedValObj(expertisestoCheckedValues)
    }, [companyData])

    // Create custom sub-industries array of object to send api on save   
    useEffect(() => {
        if (selectedSubIndustryIds.length) {
            let params = {
                "subIndustry": []
            }
            params["subIndustry"] = selectedSubIndustryIds.map((element) => "/v1/sub_industries/" + element);
            setAllSelectedSubIndustries(params)
        }
    }, [selectedSubIndustryIds])

    // Here, all selectedExpertieseIds conatain in an state array
    useEffect(() => {
        if (checkedValObj) {
            let value = []
            const expertiseCheckedIdsArray = Object.values(checkedValObj).flat();
            value.push(...expertiseCheckedIdsArray)
          
            setSelectedExpertieseIds(value)
        }
    }, [checkedValObj])

    useEffect(() => {
        // Create custom expertiese array of object to send api on save   
        if (checkedValueStatus) {
            let params = {
                "expertiese": []
            }
            params["expertiese"] = selectedExpertieseIds.map((element) => "/v1/expertieses/" + element);
            setAllSelectedExpertiese(params)

            // Invoke api and Update expertiese when we select any one
            if ((selectedExpertieseIds.length !== selectedExpertieseIdsArray.length) && checkedValueStatus) {
                const addData = formatSelectedExpertisesV2(selectedExpertieseIds);
                const apiUpdate = debounce(() => {
                    dispatch(updateSelectedExpertisesV2(addData, companyId, "getQuestion"))
                        .then(() => {
                            message.success("Information has been updated.");
                            setCheckedValueStatus(false)
                            dispatch(getCompany(companyId))
                        });
                }, 500);
                apiUpdate();
            }
        }
    }, [selectedExpertieseIds])

    // Here, select expertiese
    const handleExpertiseSelection = (list, checkedValues) => {
        setCheckedValObj((prevState) => ({
            ...prevState,
            [list]: checkedValues,
        })
        )
        setCheckedValueStatus(true)
    }
    return (
        <>
        
            {/*     <div className="expertise-classification"> */}
            {loading ?
                <Skeleton active />
                :
                <SubIndustriesExpertiese subIndustries={subIndustries} handleExpertiseSelection={handleExpertiseSelection} handleSubIndustry={handleSubIndustry} selectedSubIndustryIds={selectedSubIndustryIds} companyData={companyData} selectedExpertieseIds={selectedExpertieseIds} userRole={props.isAdmin} checkedValObj={checkedValObj} />
            }
            {/*   {
                !subGroupOption?.length ?
                    <SkeletonLoading itemsPerPage='1' /> :
                    <div className="card-body">
                        <Checkbox.Group
                            onChange={handleChange}
                            value={selectedSubGroupId}
                        >
                            <Row style={{
                                // display:'flex',
                                // overflowX: 'scroll'
                            }}>
                                {
                                    subGroupOption?.map((element, index) => {
                                      
                                        return (
                                            <Col span={24} key={index}>
                                                <Checkbox value={element.id} key={`subGroup${index}`}>
                                                    {element.industryLogo ?
                                                        <img
                                                            src={
                                                                element.industryLogo
                                                            }
                                                        /> : "None"}
                                                </Checkbox>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Checkbox.Group>
                    </div>} */}
            {/* </div> */}
        </>
    )
}

export default SubGroup;
