import React, { Component } from "react";
import axios from "axios";
import { base_url } from "utils/settings";
import { NavLink, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "actions/authActions";
import { clearCurrentProfile } from "actions/profileActions";
import { clearCommonData, setNexHubLoading } from "actions/commonActions";
import { Menu, Dropdown, Button, Skeleton, Drawer } from "antd";
import logo from "theme/assets/logo.svg";
import downArrow from "theme/assets/downArrow.png";
import sLogo from "theme/assets/nap-logo.svg";
import Avatar from "components/ui-elements/Avatar";
import styled from "@emotion/styled";
import Insuremytrans from "theme/assets/partnerLogo/Insuremytrans.png";
import cargoclaims from "theme/assets/cargoclaims-logo-64.png";
import naxExpress from "theme/assets/partnerLogo/nax-express.png";
import paycargo from "theme/assets/partnerLogo/paycargo.png";
import tiacaPortal from "theme/assets/partnerLogo/tiaca-portal.png";
import wecargo from "theme/assets/partnerLogo/wecargo.png";
import signoutIcon from "theme/assets/signoutIcon.png";
import { compose } from "redux";
import { logoData } from "actions/dataActions";
import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import CompanyEdit from "components/company-edit/CompanyEdit";
import { DOMAIN } from "utils/enum";
import mysendle from "theme/assets/partnerLogo/Logo-groen.png";

const MenuItem = styled(Menu.Item)`
  text-align: center;
  i {
    margin-right: 10px;
    opacity: 0.5;
  }
`;

class Header extends Component {
  constructor() {
    super();
    this.state = {
      showEdit: false,
      drawerWidth: 1100,
      companyLogo: [],
    };

    if (localStorage.status === "Not Finished") {
      // this.props.history.push("/register");
      window.location.href = "/register";
    }
  }
  onClickLogout = () => {
    this.props.clearCurrentProfile();
    this.props.logoutUser();
    this.props.clearCommonData();
    localStorage.removeItem("status");
  };
  componentDidMount() {
    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    console.log("companyInfo..........", companyInfo);
    const role = localStorage.getItem("rolesUser");
    // if(role === "ROLE_ADMIN"){
    //   this.props.logoData("nap_partners")
    // }
    // else{
    //   this.props.logoData(companyInfo?.company)
    // }
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        this.setState({ drawerWidth: "100%" });
      } else {
        this.setState({ drawerWidth: 1100 });
        // Set the desired width for the Drawer
      }
    };

    const getCompanyPartner = async () => {
      try {
        const res = await axios.get(
          `${base_url}/v1/company/partners/${companyInfo?.company}`
        );
        console.log("res////////////////////", res);
        // setCompanyLogo(res.data)
        // message.success("Updated Successfully")
        return true;
      } catch (err) {
        /* dispatch({
          type: GLOBAL_ERROR,
          payload: err
        }); */
        return false;
      }
    };

    handleResize(); // Call it initially to set the correct width
    // getCompanyPartner()
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }
  onClick = ({ key }) => { };

  toggleEdit = () => {
    this.setState((prevState) => ({
      showEdit: !prevState.showEdit,
    }));
  };
  handleNexHub = () => {
    this.props.setNexHubLoading();
    // commented for now due to open tab in new link
    // this.props.history.push("/nex-hub")
    window.open("/nex-hub", "_blank");
  };

  handleMySendle = () => {
    // this.props.setNexHubLoading();
    // commented for now due to open tab in new link
    // this.props.history.push("/nex-hub")
    window.open("/mysendle", "_blank");
  };

  render() {
    let mainNavigation = null,
      promoterNavigation = null,
      rightNavigation = null,
      authNavigation;
    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    const { isAuthenticated, user, logoData } = this.props.auth;

    const { logo, coverImage, domain } = this?.props?.domain;
    const { useTools } = companyInfo;
    if (isAuthenticated) {
      const menu = (
        <Menu onClick={this.onClick}>
          <div className="card-body f-s-b">
            <img
              src={user.avatar}
              style={{ width: 65, marginRight: 15, borderRadius: 8 }}
            ></img>
            <div style={{ flex: 2 }}>
              {user.email}
              <br />
              <span className="text-primary">
                {user.firstName} {user.lastName}
              </span>
            </div>
          </div>
          <Menu.Divider></Menu.Divider>
          <MenuItem key="logout" onClick={this.onClickLogout}>
            Sign out
          </MenuItem>
        </Menu>
      );

      mainNavigation = (
        <nav className="nav main__nav">
          <NavLink
            activeClassName="nav-item--active"
            className="nav-item nav-item2"
            to="/dashboard"
          >
            Dashboard
          </NavLink>
          <NavLink
            activeClassName="nav-item--active"
            className="nav-item"
            to={[DOMAIN.NEX,DOMAIN.STAGE_NEX].includes(this.props?.common?.domain?.domain) ? "/network?page=1&subIndustryRels=3" : "/network"}
          >
            Network 
          </NavLink>
          {/* {user.roles?.[0] === "ROLE_ADMIN" ? (
            <NavLink
              activeClassName="nav-item--active"
              className="nav-item"
              to="/accounting"
            >
              Accounting
            </NavLink>
          ) : (
            ""
          )} */}

          {/* <NavLink
            activeClassName="nav-item--active"
            className="nav-item"
            to="/invitations"
          >
            Invitations
          </NavLink> */}
          {useTools && (
            <>
              <NavLink
                activeClassName="nav-item--active"
                className="nav-item"
                to="/credit-alerts"
              >
                Credit Alerts
              </NavLink>

              <NavLink
                activeClassName="nav-item--active"
                className="nav-item"
                to="/promos"
              >
                Promos
              </NavLink>
              {/* {user.roles?.[0] === "ROLE_ADMIN" ? (
                <NavLink
                  activeClassName="nav-item--active"
                  className="nav-item"
                  to="/application"
                >
                  Application
                </NavLink>
              ) : (
                ""
              )} */}
              <NavLink
                activeClassName="nav-item--active"
                className="nav-item"
                to="/solution"
              >
                NAP HUB
              </NavLink>
              <NavLink
                activeClassName="nav-item--active"
                className="nav-item"
                to={`/company-profile/${companyInfo.company}`}
              >
                My Profile
              </NavLink>
              {/* <Button
                type="link"
                className="signout"
                onClick={() =>
                  this.props.history.push(
                    `/company-profile/${companyInfo.company}`
                  )
                }
              >
                My Profile
              </Button> */}
              {/* <NavLink
                activeClassName="nav-item--active"
                className="nav-item"
                to="/insurance"
              >
                Insurance
              </NavLink> */}
              {/* {user.roles?.[0] === "ROLE_ADMIN" ? (
                <NavLink
                  activeClassName="nav-item--active"
                  className="nav-item"
                  to="/partners"
                >
                  Partners
                </NavLink>
              ) : (
                ""
              )} */}
            </>
          )}
        </nav>
      );

      rightNavigation = (
        <nav className="nav main__nav">
          <NavLink
            activeClassName="nav-item--active"
            className="nav-item"
            to="/accounting"
          >
            Accounting
          </NavLink>

          {useTools && (
            <NavLink
              activeClassName="nav-item--active"
              className="nav-item"
              to="/application"
            >
              {/* Application */}
              Portal Settings
            </NavLink>
          )}
        </nav>
      );

      // { console.log("logoData....", logoData) }
      promoterNavigation =
        domain === DOMAIN.NEX
          ? logoData?.map((element) => {
            const {
              partnerLink,
              partnerLogo,
              partnerName,
              status,
              showInMenu,
            } = element;
            return (
              <nav className="nav main__nav">
                {JSON.parse(localStorage.getItem("nexHub")) === true &&
                  element?.partnerName === "NeXHUB" &&
                  showInMenu ? (
                  <Button
                    onClick={() => this.handleNexHub()}
                    style={{ border: "none", boxShadow: "none" }}
                  >
                    <img
                      src={partnerLogo}
                      alt={partnerName}
                      className="partnerIcon"
                    />
                  </Button>
                ) : (
                  element?.partnerName !== "NeXHUB" &&
                  showInMenu && (
                    <NavLink
                      className="nav-item"
                      to={{
                        pathname: partnerLink,
                      }}
                      target="_blank"
                    >
                      <img
                        src={partnerLogo}
                        alt={partnerName}
                        className="partnerIcon"
                      />
                    </NavLink>
                  )
                )}
                {/* <div
              className="nav-item"
              to={{ pathname: `https://www.cargocover.com/en/contact-us/get-started-neutral-air-partner.html` }}
              target="_blank"
            >
              <img
                src={Insuremytrans}
                alt={`Insuremytrans`}
                className="partnerIcon"
              />
            </div> */}
                {/* <NavLink
            className="nav-item"
            to={{ pathname: `http://nax.neutralairpartner.com/book/index.asp` }}
            target="_blank"
          >
            <img src={naxExpress} alt={`NAX Express`} className="partnerIcon" />
          </NavLink> */}
                {/* <NavLink
              className="nav-item"
              to={{
                pathname: `https://classic.paycargo.com/login.cfm?neutralairpartner#/home`,
              }}
              target="_blank"
            >
              <img src={paycargo} alt={`Pay Cargo`} className="partnerIcon" />
            </NavLink>
            <NavLink
              className="nav-item"
              to={{ pathname: `https://tiaca.glueup.com/account/login` }}
              target="_blank"
            >
              <img
                src={tiacaPortal}
                alt={`Tiaca Portal`}
                className="partnerIcon"
              />
            </NavLink>
            <NavLink
              className="nav-item"
              to={{ pathname: `https://www.webcargo.co/nap/` }}
              target="_blank"
            >
              <img src={wecargo} alt={`Web Cargo`} className="partnerIcon" />
            </NavLink> */}
              </nav>
            );
          })
          : logoData?.map((element) => {
            const {
              partnerLink,
              partnerLogo,
              partnerName,
              status,
              showInMenu,
            } = element;

            return (
              partnerName !== "NeXHUB" &&
              showInMenu && (
                <nav className="nav main__nav">
                  <NavLink
                    className="nav-item"
                    to={{
                      pathname: partnerLink,
                    }}
                    target="_blank"
                  >
                    <img
                      src={partnerLogo}
                      alt={partnerName}
                      className="partnerIcon"
                    />
                  </NavLink>
                  {/* <div
              className="nav-item"
              to={{ pathname: `https://www.cargocover.com/en/contact-us/get-started-neutral-air-partner.html` }}
              target="_blank"
            >
              <img
                src={Insuremytrans}
                alt={`Insuremytrans`}
                className="partnerIcon"
              />
            </div> */}
                  {/* <NavLink
            className="nav-item"
            to={{ pathname: `http://nax.neutralairpartner.com/book/index.asp` }}
            target="_blank"
          >
            <img src={naxExpress} alt={`NAX Express`} className="partnerIcon" />
          </NavLink> */}
                  {/* <NavLink
              className="nav-item"
              to={{
                pathname: `https://classic.paycargo.com/login.cfm?neutralairpartner#/home`,
              }}
              target="_blank"
            >
              <img src={paycargo} alt={`Pay Cargo`} className="partnerIcon" />
            </NavLink>
            <NavLink
              className="nav-item"
              to={{ pathname: `https://tiaca.glueup.com/account/login` }}
              target="_blank"
            >
              <img
                src={tiacaPortal}
                alt={`Tiaca Portal`}
                className="partnerIcon"
              />
            </NavLink>
            <NavLink
              className="nav-item"
              to={{ pathname: `https://www.webcargo.co/nap/` }}
              target="_blank"
            >
              <img src={wecargo} alt={`Web Cargo`} className="partnerIcon" />
            </NavLink> */}
                </nav>
              )
            );
          });
      authNavigation = (
        <>
          {/* <Dropdown
            overlay={menu}
            trigger={["click"]}
            overlayStyle={{ minWidth: 300 }}
          > */}
          <a className="ant-dropdown-link" href="#">
            <Avatar
              avatar={[{ path: user.avatar }]}
              name={user.username}
            ></Avatar>
          </a>
          {/* </Dropdown> */}
        </>
      );
    } else {
      authNavigation = (
        <nav>
          <NavLink className="nav-item" to="/login">
            Login
          </NavLink>
          {/* <NavLink className="nav-item" to="/register">
            Register
          </NavLink> */}
        </nav>
      );
    }

    return (
      <>
        <header className="header ">
          <div className="f-s-b">
            <div className="f logo-parent">
              <Link to={"/network"}>
                {/* <img src={sLogo} alt="logo" className="logo" /> */}
                {logo == undefined ? (
                  <Skeleton
                    className="logo-skeleton"
                    size="default"
                    active
                    paragraph={{ rows: 0 }}
                  />
                ) : (
                  <img
                    src={logo ? logo : ""}
                    alt="logo"
                    className="loginLogo"
                  />
                )}
              </Link>
              <img src={downArrow} alt="logo" className="logo" />
              <div className="header-overlay"></div>
              <div className="mainMenu">
                <div className="f-s-b">
                  <div className="f logo-section">
                    <Link to={"/network"}>
                      {/* <img src={sLogo} alt="logo" className="logo" /> */}
                      {/*    <img src={logo} alt="logo" className="logo" /> */}
                      {logo === undefined ? (
                        <Skeleton
                          className="logo-skeleton"
                          size="default"
                          active
                          paragraph={{ rows: 0 }}
                        />
                      ) : (
                        <img
                          src={logo ? logo : ""}
                          alt="logo"
                          className="loginLogo1"
                        />
                      )}
                    </Link>
                  </div>
                  {/* {localStorage.status === "Pending" ?
                    <div className="under_approval_box">
                      <InfoCircleFilled />
                      <p>Your application is under approval. Please visit <Button className="toggle-editProfile" onClick={this.toggleEdit}>your profile</Button> and fill in missing details</p>
                    </div>
                    : ""} */}

                  <div className="f">{authNavigation}</div>
                </div>

                <div className="submenu">
                  <div className="menuSection">
                    <div className="nav-menu-top-wrap">
                      <div className="navigationMenu menuWithTitle">
                        <div className="navigationTitle">MEMBERS</div>
                        {mainNavigation}
                      </div>
                      {user.roles?.[0] === "ROLE_ADMIN" && (
                        <div className="navigationMenu menuWithTitle">
                          <div className="navigationTitle">ADMIN</div>
                          {rightNavigation}
                        </div>
                      )}
                    </div>
                    {/* {logoData?.some((e) => e.status === "1") && ( */}
                    {(logoData?.length > 0 && [DOMAIN.NEX, DOMAIN.STAGE_NEX].includes(this.props.common.domain?.domain)) &&
                    logoData.some((item) => item.showInMenu) ? (
                      <div className="partnerMenu menuWithTitle">
                        <div className="navigationTitle">OUR TOOLS</div>

                        {promoterNavigation}
                        <nav className="nav main__nav">
                          <Button
                            onClick={() => this.handleMySendle()}
                            style={{ border: "none", boxShadow: "none" }}
                          >
                            <img
                              src={mysendle}
                              alt={mysendle}
                              className="partnerIcon"
                            />
                          </Button>
                        </nav>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* )} */}
                  </div>
                  <div className="authSection">
                    <div className="initialTxt">Hello and welcome</div>
                    <span className="text-primary">
                      {user.firstName} {user.lastName}
                    </span>
                    <div className="actionList">
                      <Button
                        type="link"
                        className="signout"
                        onClick={this.onClickLogout}
                      >
                        Sign out
                        <img src={signoutIcon} alt="Signout" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {localStorage.status === "Pending" ? (
                <div className="under_approval_box">
                  <InfoCircleFilled />
                  <p>
                    Your application is under approval. Please visit{" "}
                    <Button
                      /* type='text' */ className="toggle-editProfile"
                      onClick={this.toggleEdit}
                    >
                      your profile
                    </Button>{" "}
                    and fill in missing details
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="f">{authNavigation}</div>
            <Drawer
              width={this.state.drawerWidth}
              destroyOnClose={true}
              closable={false}
              onClose={this.toggleEdit}
              visible={this.state.showEdit}
            >
              <CompanyEdit
                key={companyInfo?.company}
                companyId={companyInfo?.company}
                toggleEdit={this.toggleEdit}
              ></CompanyEdit>
            </Drawer>
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  domain: state?.common?.domain,
  data: state?.company?.data,
  registrationStatus: state?.common?.registrationStatus,
  common: state?.common,
  userData: state?.data,
  userSettings: state?.common,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    logoutUser,
    clearCurrentProfile,
    clearCommonData,
    setNexHubLoading,
  })
)(Header);
