import React from 'react';
import playStore from 'theme/assets/playStore.svg'
import appleStore from 'theme/assets/appleStore.svg'
import phone from 'theme/assets/phone.png'
import newPhone from 'theme/assets/newPhone.png'
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const MobileAlert = () => {
    const continueToDesktop = () => {
        localStorage.setItem("continueDesktop", 1);
        window.location.href = "/login";
    }

    return (
        <div className="mobileAlertContainer">
            <div className="appStoreIcons">
                <Link to={{ pathname: `https://play.google.com/store/apps/details?id=com.neutralairpartner` }} className="playStore" target="_blank">
                    <img src={playStore} />
                </Link>
                <Link to={{ pathname: `https://apps.apple.com/us/app/neutral-air-partner/id1494082023?ign-mpt=uo%3D2` }} className="appleStore" target="_blank">
                    <img src={appleStore} />
                </Link>
            </div >
            <div className="continueLinkContainer">
                <Button type="link" onClick={() => continueToDesktop()}>
                    Continue to Desktop Portal
                </Button>
            </div>
            <div className="mobileContainer">
                {/* <img src={phone} /> */}
                <img src={newPhone} />

            </div>
        </div >
    )
}

export default MobileAlert;