import React, { Component } from 'react'
import { Col, Card, Tag } from 'antd';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { setNexHubLoading } from 'actions/commonActions';
import { DOMAIN } from 'utils/enum';

class Partner extends Component {
    constructor(props) {
        super(props)
    }

    oneClick() {
        if (this.props.id == 14 || this.props.partnerName === 'Parcel')
            window.open("/mysendle", "_blank");
        else if ((this.props.id == 7 || this.props.partnerName === 'NeXHUB')) {
            this.props.setNexHubLoading();
            window.open("/nex-hub", "_blank");
            return true;
        }
        else
            window.open(this.props.partnerLink, "_black");
    }

    render() {
        const { partnerLogo, partnerName, apiFlag, id } = this.props;
        return (
            <Col span="12">
                <Card className={`partner-wrapper ${apiFlag ? 'apiflag-border' : ''}`} hoverable={true}
                    onMouseEnter={this.props.onMouseenter.bind(this, id)}
                    onMouseLeave={this.props.onMouseleave.bind(this)}
                    onClick={this.oneClick.bind(this)}
                >
                    <img src={partnerLogo} alt={partnerName} className='partner-logo-img' />
                    {apiFlag ? <Tag className='api-flag-btn'>One Click Access</Tag> : null}
                </Card>
            </Col>
        )
    }
}

const mapStateToProps = (state) => ({});

export default compose(withRouter, connect(mapStateToProps, {
    setNexHubLoading
}))(Partner)