import isEmpty from "../utils/isEmpty";

import {
  SET_CURRENT_USER,
  AUTH_ERROR,
  EDIT_PROFILE,
  EDIT_AVATAR,
  AUTH_LOADING,
  CLEAR_AUTH,
  SET_LOGODATA,
  UPDATE_TRIGGER,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  error: null,
  user: null,
  loading: false,
  popup: {},
  popuploading:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        loading: false,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_LOGODATA:
      return {
        ...state,
        // loading: false,
        logoData: action.payload,
      };
    case UPDATE_TRIGGER:
      return {
        ...state,
        // loading: false,
        triggerData: action.payload,
      };
    case EDIT_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload.email,
          name: action.payload.name,
        },
      };
    case EDIT_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload,
        },
      };

    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "SET_POP": return { ...state, popup: action.payload };
    case "LOAD_POP": return {...state,popuploading:action.payload};
    default:
      return state;
  }
}
