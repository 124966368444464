import React/* , { useState, useEffect } */ from "react";
import AirCargoSalesAgent from "./AirCargoSalesAgent";
/* import NeutralAirConsolidator from "./NeutralAirConsolidator";
import GSA from "./GSA";
import CharterBroker from "./CharterBroker";
import CoolChainExport from "./CoolChainExport";
import DangerousGoodsExpert from "./DangerousGoodsExpert";
import ExpressOperator from "./ExpressOperator";
import TimeCriticalExpert from "./TimeCriticalExpert";
import TerminalHandlingAgent from "./TerminalHandlingAgent";
import EcommerceSpecialist from "./EcommerceSpecialist";
import SeaAirOperator from "./SeaAirOperator";
import RfsOperator from "./RfsOperator"; */

export default function ExpertiseListing({
  // expertiseList,
  expertises,
  // classification,
  // companyId,
  fileStatus,
  fileName,
  setValidationErrorAirCargo,
  selectedExpertieseIds,
  selectedSubIndustryIds
}) {

  let content = [];
  /* if (expertises?.length === 0) {
    return <div>Please select expertises...</div>;
  } */

  /*   expertises.forEach(id => {
      if (expertiseList.length) {
  console.log("expertiseList",expertiseList)
        const title = expertiseList.filter((val) => {
  
          if (id === val.value)
            return val.label
        })
        console.log("id...", id)
        switch (id) {
          case 3:
            content.push(<GSA key={id} title={title[0]}></GSA>);
            break;
          case 5:
            content.push(<CharterBroker key={id} title={title[0]}></CharterBroker>);
            break;
          case 6:
            content.push(<CoolChainExport key={id} title={title[0]}></CoolChainExport>);
            break;
          case 7:
            content.push(<DangerousGoodsExpert key={id} title={title[0]}></DangerousGoodsExpert>);
            break;
          case 8:
            content.push(<ExpressOperator key={id} title={title[0]}></ExpressOperator>);
            break;
          case 9:
            content.push(<TimeCriticalExpert key={id} title={title[0]}></TimeCriticalExpert>);
            break;
          case 10:
            content.push(<TerminalHandlingAgent key={id} title={title[0]}></TerminalHandlingAgent>);
            break;
          case 11:
            content.push(<EcommerceSpecialist key={id} title={title[0]}></EcommerceSpecialist>);
            break;
          case 12:
            content.push(<SeaAirOperator key={id} title={title[0]}></SeaAirOperator>);
            break;
          case 13:
            content.push(<RfsOperator key={id} title={title[0]}></RfsOperator>);
            break;
          default:
            return;
        }
      }
    }); */

  // AirCargoSalesAgent && Neutral Air Consolidator
  /*  if (expertises.indexOf(1) > -1 && expertises.indexOf(2) > -1) {
     console.log("if 1")
     content.push(
       <AirCargoSalesAgent
         key={expertises.indexOf(1) + expertises.indexOf(2)}
         air
         neutral
       ></AirCargoSalesAgent>
     );
   } else {
     console.log("else.") */
  // Just AirCargoSales
  if (expertises.indexOf(1) > -1) {
    content.push(
      <AirCargoSalesAgent
        key={expertises.indexOf(1)}
        air
        fileStatus={fileStatus}
        fileName={fileName}
        setValidationErrorAirCargo={setValidationErrorAirCargo}
        selectedExpertieseIds={selectedExpertieseIds}
        selectedSubIndustryIds={selectedSubIndustryIds}
      ></AirCargoSalesAgent>
    );
  } /* else {
      console.log("else 2")
      // Just NeutralAirConsolidator
      if (expertises.indexOf(2) > -1) {
        console.log("else if 2")
        content.push(
          <AirCargoSalesAgent
            key={expertises.indexOf(1)}
            neutral
          ></AirCargoSalesAgent>
        );
      }
    } */
  // }

  return (
    <div>
      {content.sort(function (a, b) {
        return a.key - b.key;
      })}
    </div>
  );
}
