import React, { Component } from 'react'
import { Row, Col, Icon, Button, Skeleton, Empty } from 'antd'
import { api_v, base_url } from 'utils/settings';
import axios from 'axios';
import Partner from './Partner';
import LoadingPartner from './LoadingPartner';
import localeInfo from "rc-pagination/lib/locale/en_US";
import Pagination from "rc-pagination";

export default class Solution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            filter: undefined,
            partners: [],
            loading: true,
            loadingTools: false,
            pagination: {},
            activeParter: {
                // id: 'default',
                // partnerLogo: "https://neutralairpartner.com/download/spec_icons/v2/nas-b.svg",
                // partnerLogo:"https://lee.neutralairpartner.com/uploads/partnerLogo/tiaca-portal.png",
                // description: "Neutral Air Partner has formed and will continue to strengthen relationships with key solutions providers and partners to drive growth and success for its members",
                // partnerLink: 'https://neutralairpartner.com/pre-signup-v2/'
            },
            currentPage: 1

        }
        this.axios = axios.create({
            baseURL: `${base_url}/${api_v}`,
            headers: {
                'Authorization': `Bearer ${localStorage.jwtToken}`
            }
        })
    }

    async fetchCategories(data) {
        // limit: 2
        let params = { ...data }
        this.setState({ loading: true })
        try {
            const { data } = await this.axios.get(`company/partners_with_cat/`, {
                params
            });
            this.setState({ partners: data.data });
            if (data.pagination)
                this.setState({ pagination: data.pagination })
        } catch (error) {
            if (error.response.status === 404)
                this.setState({ partners: [], pagination: {} })
        }
        this.setState({ loading: false })
    }

    changePage(currentPage) {
        let params = { categoryId: this.state.filter, page: currentPage }
        this.fetchCategories(params);
        this.setState({ currentPage })
    }

    async fetchTools() {
        this.setState({ loadingTools: true });
        try {
            const { data } = await this.axios.get("tools/categories");
            let filters = [];
            const icons = {
                Associations: 'flag',
                "E-Commerce": 'credit-card',
                "Courier and express": 'thunderbolt',
                "Technology firm": 'build',
                "verification tool": "flag"
            }
            if (data.data)
                filters = data.data.map((filter => ({ ...filter, type: icons[filter.name] })));
            this.setState({ filters });
        } catch (error) {
            if (error.response.status === 404)
                this.setState({ filters: [] });

            console.log("error", error)
        }
        this.setState({ loadingTools: false });
    }
    componentDidMount() {
        this.fetchCategories({});
        this.fetchTools();
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filter !== this.state.filter) {
            let params = { page: 1 };
            if (this.state.filter !== undefined) {
                params = { ...params, categoryId: this.state.filter }
                this.fetchCategories(params);
            }
        }
        // if (prevState.currentPage !== this.state.currentPage) {
        //     console.log(this.state.currentPage)

        // }
    }

    onMouseenter(activeId) {
        const activeParter = this.state.partners.find(partner => partner.id === activeId)
        this.setState({ activeParter })
    }

    onMouseleave() {
        this.setState({
            activeParter: {
                // id: 'default',
                // partnerLogo: "https://neutralairpartner.com/download/spec_icons/v2/nas-b.svg",
                // // partnerLogo:"https://lee.neutralairpartner.com/uploads/partnerLogo/tiaca-portal.png",
                // description: `Neutral Air Partner has formed and will continue to strengthen relationships with key solutions providers and partners to drive growth and success for its members`,
                // partnerLink: 'https://neutralairpartner.com/pre-signup-v2/'
            }
        })
    }

    onClear() {
        this.setState({ filter: undefined, currentPage: 1 })
        this.fetchCategories({});
    }

    render() {
        return (
            <div className="pageContainer solutionContainer">
                <Row className='form-container app-module-row-main container solution-wrapper' style={{
                    height:'auto',
                }}>
                    <Col span="6" className='filter-wrapper' >
                        <div>
                            <div className='filter-btn-wrapper'>
                                <h2 style={{ fontWeight: 'bold' }}>Filter</h2>
                                {this.state.filter !== undefined && <Button type='link' className='clear-btn' onClick={this.onClear.bind(this)}>CLEAR X</Button>}
                            </div>
                            <ol style={{
                                listStyleType: 'none',
                                padding: "10% 0"
                            }}>
                                {this.state.loadingTools ? (<li>
                                    <Skeleton active />
                                </li>) : this.state.filters.map(({ name, type, id }, i) => (
                                    <li key={id} onClick={this.setFilter.bind(this, id)}>
                                        <div style={{ cursor: 'pointer' }} className='category-tools'>
                                            {/* <Icon type={type} style={{ fontSize: 24 }}
                                                className={`${id === this.state.filter ? 'active-icon' : ''}`}
                                            /> */}
                                            <span style={{ fontSize: 18 }}
                                                className={`${id === this.state.filter ? 'active-text' : ''}`}
                                            >{name}</span>
                                        </div>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </Col>
                    <Col span="12" >
                        <div>
                            <h2 style={{ fontWeight: 'bold' }}>NAP  HUB</h2>
                            <Row className='partner-listing-wrapper'>
                                {this.state.loading ? <LoadingPartner /> : (
                                    this.state.partners.length === 0 ? <Empty className='empty-partner' /> : this.state.partners.map(partner => <Partner {...partner} key={partner.id}
                                        onMouseenter={this.onMouseenter.bind(this)}
                                        onMouseleave={this.onMouseleave.bind(this)}
                                    />)
                                )}
                            </Row>
                            {this.state.pagination && this.state.pagination.total_pages > 1 ? (
                                <div className='pagination-wrapper'>
                                    <Pagination
                                        onChange={this.changePage.bind(this)}
                                        locale={localeInfo}
                                        current={this.state.pagination?.current_page}
                                        defaultPageSize={2}
                                        total={this.state.pagination?.total_results}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </Col>         
                    <Col span="6" >
                        <div className='partner-derscription-wrapper'>
                            <img src={this.state.activeParter.partnerLogo} />
                            <p>{this.state.activeParter.description}</p>
                            {this.state.activeParter.id === 'default' && (
                                <a href={this.state.activeParter.partnerLink} target='_blank'>
                                    {this.state.activeParter.id === 'default' ? 'Apply for NAS network' : this.state.activeParter.partnerLink}
                                </a>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
