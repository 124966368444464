import React from 'react';
import PropTypes from 'prop-types';

const ReactToolTip = ({
    title,
    isDom,
    children,
    position,
    containerClass,
    theme,
    expertise,
    subIndustryName,
    fileStatus,
    className,
    subId,
    id
}) => {
  
    return (
        fileStatus === "networkSidebar" ?
            <div className={`tooltip Network-parent ${containerClass} ${className ? className : ''}`}>
                {children}
                <div
                    className={`tooltiptext ${theme === 'dark' ? `dark` : `light`
                        } tooltip-${position}`}
                >
                    {expertise?.length !== 0
                        ?
                        <>
                            <h3>{title}</h3>
                            <div className='tipList'>
                                {expertise?.map((value) => {
                                    return (
                                        value.subIndustry?.id === subId
                                        &&
                                        <div className='tipItem' key={value.id}>
                                            <div id='left-side-image'></div>
                                            <p>{value.name}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <div className='tipList'>
                            <div className='tipItem'>
                                <p>{subIndustryName}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
            :
            <div className={`tooltip ${containerClass}`}>
                {children}
                <div
                    className={`tooltiptext ${theme === 'dark' ? `dark` : `light`
                        } tooltip-${position}`}
                >
                    {/* {isDom ? (
                    <div dangerouslySetInnerHTML={{ __html: title }}></div>
                ) : ( */}

                    {title}
                    {/*  )} */}
                    {/*   <span className="arrow"></span> */}
                </div>
            </div>
    );
};

export default ReactToolTip;

ReactToolTip.defaultProps = {
    title: 'sample',
    children: React.createElement('div'),
    position: 'bottom',
    containerClass: '',
    theme: 'light',
};

ReactToolTip.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element,
    position: PropTypes.string,
    containerClass: PropTypes.string,
    theme: PropTypes.string,
};
