import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Skeleton, Icon, notification } from 'antd'
import { DOMAIN } from 'utils/enum';
import axios from 'axios';
import { api_v, base_url } from 'utils/settings';

const Payment = () => {

    const { logo, domain } = useSelector((state) => state?.common?.domain);
    const [loading, setLoading] = useState(false);

    const handleSyncPayment = (domain) => {
        let link;
        if (domain === DOMAIN.NEX || domain === DOMAIN.NAP) {
            link = "https://lee.neutralairpartner.com/xero/payment/check"
        } else {
            link = "https://staging.neutralairpartner.com/xero/payment/check"
        }
        window.open(link, "_blank");
    }

    const downloadLogs = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${base_url}/${api_v}logs/xero-payment-sync/latest`);
            if (data?.download_file)
                window.open(data?.download_file, "_blank");

        } catch (error) {
            notification.error({ description: "Something went wrong. please try again." })
        }
        setLoading(false);
    }
    return (
        <Row className='payment-card-parent' gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
        }}>
            <Col className="gutter-row login-logo-parent" span={24}>
                <img className="loginLogo" src={logo} />
            </Col>
            <Col className="gutter-row sync-payment-btn-parent downloadlog-wrapper " span={24} >
                <Button type="secondary" disabled={loading} onClick={downloadLogs} className="invite-user-cancel-btn connection-edit-btn btn-outlook">
                    {loading && <Icon type="loading" spin />}
                    Download Log
                </Button>
                <Button type="primary" className="create-btn sync-payments" onClick={() => handleSyncPayment(domain)}>
                    Sync payments
                </Button>
            </Col>
        </Row>
    )
}
export default Payment;