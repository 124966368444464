import React, { useState } from "react";
import { Col } from "antd";
import TradeStats from "./trade-stats/TradeStats";
import Map from "./map/Map";
import Members from "./members/Members";
import Contacts from "./vendor-contacts/Contacts";
import { editVendorCondition, vendorDocContion } from "utils/functions";
/* import { getNetworkNewMembers } from '../../../../actions/dataActions' */

const RightPane = ({ company, cities, activeTab }) => {
	const [ids] = useState(company?.subIndustry.map(({ id }) => id));
	let existVendor = company?.subIndustry?.filter(item => item?.id === 7)?.length;
	return (
		/* company.companySettings[0].classification === "Vendor" || */
		<> 
		   
		    {(ids.length == 1 && ids.includes(7)) && <Contacts company={company} />}
			{!ids.includes(7) && (<Col span={24} className="h-screen text-center associate-right-pane" style={{ background: 'linear-gradient(to right,  #ffffff 0%, #ffffff 0%, #282e45 0%, #282e45 0%)' }}>
					{activeTab === 'Promos' && <Members /* data={data}  */ />}
					<div className={activeTab === 'Promos' ? 'hidden' : null}>
						{company.companyData && (<TradeStats companyData={company.companyData} />)}
						<Map
							cityId={company.cityId}
							country={company.countries}
							cities={cities}
							importCountries={company.airImportCountry}
							exportCountries={company.airExportCountry}
						/>
					</div>
				</Col>)}
			{ids.length > 1 && ids.includes(7) && (<div className="map-contact-wrapper">
				<Col span={24} className="h-screen text-center associate-right-pane" style={{ background: 'linear-gradient(to right,  #ffffff 0%, #ffffff 0%, #282e45 0%, #282e45 0%)' }}>
					{activeTab === 'Promos' && <Members /* data={data}  */ />}
					<div className={activeTab === 'Promos' ? 'hidden' : null}>
						{company.companyData && (<TradeStats companyData={company.companyData} />)}
						<Map
							cityId={company.cityId}
							country={company.countries}
							cities={cities}
							importCountries={company.airImportCountry}
							exportCountries={company.airExportCountry}
						/>
					</div>
				</Col>
				<Contacts company={company} />
			</div>)}
		</>
	);
};

export default RightPane;
