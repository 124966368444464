import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import { base_url } from "utils/settings";
import {
  updateCompanyData,
  createFee,
  updateFee,
  deleteFee,
  deleteDocument,
  updateBank,
} from "actions/companyActions";
import { uploadDocument } from "actions/uploadActions";
import { findRateUrl, generateId } from "utils/functions";
import Tabs from "components/ui-elements/Tabs";
import UsersEdit from "./users/UsersEdit";
import Expertise from "./expertise/Expertise";
import Mawb from "./mawb/Mawb";
import Legal from "./legal/Legal";
import Fees from "./fees/Fees";
import CompanyDetails from "./details/CompanyDetails";
import VendorDirectory from "./upload-directory/VendorDirectory";
import Certificates from "./certificates/Certificates";
import Event from "./event/Event";
import { api_v } from "utils/settings";
import Bank from "./bank/Bank";
import CompanyLogoData from "./CompanyLogoData";

const CompanyHq = (props) => {
  const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
  const { company } = props;
  const { companyData, companyBankInfo } = company;
  const companyId = company.id;
  const companyDataId = companyData[0]?.id;
  const companyBankInfoId = companyBankInfo[0]?.id;
  const [CompanyLogo, setCompanyLogo] = useState([]);
  const logoContent = useSelector((state) => state.auth.logoData);
  console.log("logoContent...", logoContent)
  console.log("CompanyLogo...", CompanyLogo)

  const initialFunction = async () => {
    try {
      const res = await axios.get(`${base_url}/v1/company/partners/${companyId}`);
      setCompanyLogo(res.data)
      return true;
    } catch (err) {
      /* dispatch({
        type: GLOBAL_ERROR,
        payload: err
      }); */
      return false;
    }
  }

  useEffect(() => {
    initialFunction()
  }, []);


  const saveMawb = (values) => {
    return props.updateCompanyData(values, companyDataId, 'noloading');
  };
  const onCreateFee = (row, id, name) => {
    // Data for api
    const targetUrl = findRateUrl(name);

    let data = {
      company: `/${api_v}company/${companyId}/profile`,
    };
    data[`${name}Fee`] = row[`${id}${name}Fee`];
    data[`${name}Price`] = row[`${id}${name}Price`];
    return props.createFee(data, targetUrl, name);
  };

  const onUpdateFee = (row, id, name) => {
    // Data for api
    const targetUrl = findRateUrl(name);

    let data = {};
    data[`${name}Fee`] = row[`${id}${name}Fee`];
    data[`${name}Price`] = row[`${id}${name}Price`];

    // API request
    return props.updateFee(data, targetUrl, id, name);
  };
  const onDeleteFee = (id, name) => {
    return props.deleteFee(findRateUrl(name), id, name);
  };

  // Bank
  const handleBankSave = (values) => {
    return props.updateBank(values, companyId);
  };

  // Handle Dropzone
  const uploadFiles = (files, subject) => {
    let data;

    files.forEach((file) => {
      // Data for Upload
      data = new FormData();
      data.append("file", file);
      data.append("originalName", file.name);
      data.append("source", subject);

      // Data for Preview and progress
      const previewData = {
        id: generateId(),
        progress: 0,
        size: file.size,
        text: file.name.replace(/\.[^/.]+$/, ""),
        type: file.type,
      };

      // Must include all prev documents
      const prevDocuments = props.documents.map(
        (prevDoc) => `/${api_v}documents/${prevDoc.id}`
      );
      // API request
      props.uploadDocument(data, previewData, prevDocuments, companyId);
    });
  };

  // Delete File
  const deleteDocument = (id) => {
    return props.deleteDocument(id);
  };

  // Render
  const {
    classification,
    fob,
    dap,
    importhandover,
    rates,
    documents,
    bankReference,
    auth,
  } = props;
  return (
    <div>
      <Tabs
        className="left-tab"
        activeTab={{
          id: 0,
        }}
        vertical={true}

      >
        <Tabs.Tab title="Members" icon="group" id={0} >
          <UsersEdit
            companyId={company?.id}
            users={company?.userSettings}
          ></UsersEdit>
        </Tabs.Tab>
        <Tabs.Tab title="Expertises & Focus" icon="area-chart" id={1}>
          <Expertise companyId={company?.id}></Expertise>
        </Tabs.Tab>
        {/* classification !== "Vendor" */  company?.subIndustry?.filter(item => item.id === 7)?.length === 0 ? (
          <Tabs.Tab title="MAWB/CNEE & Notes" icon="file-text" id={2}>
            <Mawb handleSave={saveMawb} data={company?.companyData[0]}></Mawb>
          </Tabs.Tab>
        ) : null}
        {/* classification !== "Vendor" */   company?.subIndustry?.filter(item => item.id === 7)?.length === 0 ? (
          <Tabs.Tab title="Fees & Charges" icon="dollar" id={3}>
            <Fees
              companyId={company.id}
              fob={fob}
              dap={dap}
              importhandover={importhandover}
              rates={rates}
              documents={documents}
              onUpload={uploadFiles}
              onDocumentDelete={deleteDocument}
              onCreateFee={onCreateFee}
              onUpdateFee={onUpdateFee}
              onDeleteFee={onDeleteFee}
            ></Fees>
          </Tabs.Tab>
        ) : (
          <Tabs.Tab title="Upload directory" icon="upload" id={3}>
            <VendorDirectory></VendorDirectory>
          </Tabs.Tab>
        )}
        <Tabs.Tab title="Company Details" icon="briefcase" id={4}>
          <CompanyDetails></CompanyDetails>
        </Tabs.Tab>
        <Tabs.Tab title="Legal information" icon="legal" id={5}>
          <Legal
            companyId={companyBankInfoId}
            bankReference={bankReference}
            handleBankSave={handleBankSave}
          ></Legal>
        </Tabs.Tab>
        <Tabs.Tab title="Certificates" icon="certificate" id={6}>
          <Certificates></Certificates>
        </Tabs.Tab>
        {/*  {auth.user.roles[0] === "ROLE_ADMIN" && (
          <Tabs.Tab title="Event" icon="globe" id={7}>
            <Event />
          </Tabs.Tab>
        )} */}
        {/* {auth.user.roles[0] === "ROLE_ADMIN" && */}
        {((logoContent?.length > 0 && logoContent.some(item => item.showInTool)) || (CompanyLogo?.length > 0 && CompanyLogo.some(item => item.showInTool)))
          &&
          < Tabs.Tab title="Tools" icon="group" id={8}>
            <CompanyLogoData
              initialFunction={initialFunction}
              logoContent={(JSON.parse(localStorage.getItem('loggedInUserCompanyId')) === companyId) ? logoContent : CompanyLogo}
              companyId={props?.company?.id}
            />
          </Tabs.Tab>
        }
        {/* } */}
        {/* {
          auth.user.roles[0] === "ROLE_ADMIN" ? (
            props?.company?.id !== companyInfo?.company && (
              <Tabs.Tab title="Partner" icon="group" id={8}>
                <CompanyLogoData
                  logoContent={logoContent}
                  companyId={props?.company?.id}
                />
              </Tabs.Tab>
            )
          ) : (
            // props?.company?.id !== companyInfo?.company && (
            <Tabs.Tab title="Partner" icon="group" id={8}>
              <CompanyLogoData
                logoContent={logoContent}
                companyId={props?.company?.id}
              />
            </Tabs.Tab>
          )
          // )
        } */}
        {/* {props?.company?.id !== companyInfo?.company && (
          <Tabs.Tab title="Partner" icon="group" id={8}>
            <CompanyLogoData
              logoContent={logoContent}
              companyId={props?.company?.id}
            />
          </Tabs.Tab>
        )} */}
        {/* {
          (props?.company?.id !== loggedInUserCompany?.company) &&(
            <Tabs.Tab title="Partner3" icon="group" id={8}>
              <CompanyLogoData
                logoContent={logoContent}
                companyId={props?.company?.id}
              />
            </Tabs.Tab>
          )
         
        } */}
        {/* <Tabs.Tab title="Bank Information" icon="bank" id={7}>
          <Bank
            bankReference={bankReference}
            handleBankSave={handleBankSave}
          ></Bank>
        </Tabs.Tab> */}
      </Tabs>
    </div >
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company.data,
  classification: state.company.data?.companySettings[0]?.classification,
  fob: state.company.data.fob,
  dap: state.company.data.dap,
  importhandover: state.company.data.importhandover,
  rates: state.company.data.rates,
  documents: state.company.data.document,
  bankReference: state.company.data.companyBankInfo[0],
});

export default connect(mapStateToProps, {
  updateCompanyData,
  createFee,
  updateFee,
  deleteFee,
  deleteDocument,
  updateBank,
  uploadDocument,
})(CompanyHq);
