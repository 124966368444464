import { Col, Row, Skeleton, Card } from 'antd'
import React, { Component } from 'react'

export default class LoadingPartner extends Component {
    constructor(prop) {
        super(prop)
        this.state = {
            cols: [1, 2, 3, 4]
        }
    }
    render() {
        return (
            <Row className='loading-partner-wrapper'>
                {this.state.cols.map(col => (
                    <Col offset="1" span="11" key={col} className='loader-card'>
                        <Card >
                            <Skeleton active  style={{height:64 }} />
                        </Card>
                    </Col>
                ))}
            </Row>
        )
    }
}
