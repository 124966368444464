import React, { useState } from "react";
import { connect } from "react-redux";
import Dropzone from "components/ui-elements/Dropzone";
import FilePreview from "components/ui-elements/FilePreview";
import { generateId } from "utils/functions";
import { uploadDocument } from "actions/uploadActions";
import { api_v } from "utils/settings";
import {
  deleteDocument,
} from "actions/companyActions";
import { Modal } from "antd";

const VendorDirectory = props => {
  const { companyId, documents } = props;

  // const [active, setActive] = useState(null);
  // const [visible, setVisible] = useState(false);

  // Handle Dropzone
  const uploadFiles = files => {
    let data;

    files.forEach(file => {
      // Data for Upload
      data = new FormData();
      data.append("file", file);
      data.append("source", "directory");

      // Data for Preview and progress
      const previewData = {
        id: generateId(),
        progress: 0,
        size: file.size,
        text: file.name.replace(/\.[^/.]+$/, ""),
        type: file.type
      };

      // Must include all prev documents
      const prevDocuments = props.documents.map(
        prevDoc => `/${api_v}documents/${prevDoc.id}`
      );
      // API request
      props.uploadDocument(data, previewData, prevDocuments, companyId);
    });
  };

  // Delete File
  const deleteDocument = id => {
    return props.deleteDocument(id);
  };

  // const showDocument = (id) => {
  //   setVisible(true);
  //   setActive(documents.find(document => id === document.id));
  // }

  // const onModalClose = () => {
  //   setVisible(false);
  // }

  // const onNext = () => {
  //   setActive(documents[documents.indexOf(active) + 1])
  // }

  // const onPrev = () => {
  //   setActive(documents[documents.indexOf(active) - 1])
  // }

  return (
    <div className="card">
      <div className="card-header">
        <h4>Documents</h4>
      </div>

      <div className="card-body">
        {/* <Modal footer={null} visible={visible} onCancel={onModalClose}>
          <img src={active?.path} style={{ width: "100%", position: "relative" }} />
          <div>
              {documents.indexOf(active) > 0 && <button onClick={onPrev} style={{float:'left'}}>{`<`}</button>}
              {documents.indexOf(active) < (documents.length - 1) && <button onClick={onNext} style={{float:'right'}}>{`>`}</button>}
          </div>
        </Modal> */}
        {documents.map(document => (
          // <div onClick={() => showDocument(document.id)}>
            <FilePreview
              key={document.id}
              id={document.id}
              onDelete={deleteDocument}
              name={document.originalName}
              link={document.path}
            />
          // </div>
        ))}
        <Dropzone handleDrop={uploadFiles} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  companyId: state.company.data.id,
  documents: state.company.data.document
});

export default connect(mapStateToProps, { uploadDocument, deleteDocument })(VendorDirectory);
