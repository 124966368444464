import React, { Component } from "react";
import { connect } from "react-redux";
import CustomSelect from "components/ui-elements/Select";
import { Input, Radio, Button, Checkbox, Col, Skeleton, Tooltip, message, Collapse } from "antd";
import {
  fetchCities,
  getFilterLists,
  getAirlines,
  clearFilters,
  setFirstTab,
  // setAllcompaniesTab,
  getAirports,
  getSubGroupList,
  // getCertificates,
  getNewExpertieseList
} from "actions/dataActions";
import cargocover from "theme/assets/partnerLogo/cargocover.png";
import axios from "axios";
import { base_url, api_v } from "utils/settings";
import { debounce } from "utils/functions";
import activeAllNetwork from 'theme/assets/nap-dark.svg'
import deactiveAllNetwork from 'theme/assets/nap.svg'
import deactiveVendor from 'theme/assets/vendor-dark.svg'
import activeVendor from 'theme/assets/vendor.svg'
import { DOMAIN, NETWORK } from "utils/enum"
import {
  formatAirlineOption,
  formatAirportOption,
} from "components/helper/Format";
import qs from "qs";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import CustomToolTip from './CustomTooltip'
import { SET_ALL_COMPANIES_TAB } from "actions/types";
import NetworkPanel from "./NetworkPanel";
const { Search } = Input;

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

const statusList = [
  {
    id: "Approved",
    label: "Approved",
  },
  {
    id: "Blocked",
    label: "Blocked/Resigned",
  },
  {
    id: "Pending",
    label: "Pending",
  },
  {
    id: "Not Finished",
    label: "Not Finished",
  },
];

const importExportList = [
  {
    id: "all",
    label: "All",
  },
  {
    id: "import",
    label: /* "Import From Country" */ "Top import countries",
  },
  {
    id: "export",
    label: /* "Export To Country" */ "Top export countries",
  },
];

const { Panel } = Collapse;

class NetworkSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // clearFilterStatus: false,
      companyName: this.props.filters.companyName,
      fos_email: this.props.filters.fos_email,
      status: this.props.filters.status,
      country: this.props.filters.country,
      city: this.props.filters.city,
      classification: this.props.filters.classification,
      airIndustryExpertise: this.props.filters.airIndustryExpertise,
      associateIndustryExpertise: this.props.filters.associateIndustryExpertise,
      vendorIndustryExpertise: this.props.filters.vendorIndustryExpertise,
      specializations: this.props.filters.specializations,
      importCountry: this.props.filters.importCountry,
      exportCountry: this.props.filters.exportCountry,
      /*    importExport: "import", */
      importExport: "all",
      flag: true,
      expertise: "",
      originAirport: this.props.filters.originAirport,
      destinationAirport: this.props.filters.destinationAirport,
      subIndustryRels: this.props.subIndustryRels,
      airline: this.props.filters.airline,
      selectedSubIndustryId: '',
      expertiesesListBySelectedSubIndustry: [],
      expertieseListing: [],
      expertiseRels: '',
      // activeNetworkPanel: [],
    };

    this.autocompleteSearchDebounced = debounce(this.autocompleteSearch, 1000);
    this.autocompleteSearchDebouncedByUserEmail = debounce(this.autocompleteSearchByUserEmail, 1000);
  }

  colorMap = {
    [NETWORK.NAC_NEUTRAL_AIR_CONSOLIDATOR]: "blue",
    [NETWORK.NAV_NEUTRAL_AVIATION_SPECIALIST]: "orange",
    [NETWORK.NEX_E_COMMERCE]: "red",
    [NETWORK.NAX_24_BY_7]: "purple",
    [NETWORK.NAX_FRESH]: "green",
    [NETWORK.NAF]: "yellow", // color not provided by client end,
    [NETWORK.VENDORS]: "black", // color not provided by client end
  };

  changeNetworkFilter(subIndustryRels) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search)
    if (params.has('country'))
      params.delete('country')

    if (params.has('city'))
      params.delete('city');

    if (params.has('expertiseRels'))
      params.delete('expertiseRels');
    url.search = params;
    console.log('checking url...', url.toString())
  }

  changePanel(active) {
    const activeNetworkPanel = active.slice(-1);
    // this.setState({ activeNetworkPanel })
    this.props.changefilterPanel(activeNetworkPanel)
    if (activeNetworkPanel.length == 0) this.resetFilters();
    this.handleSpecialityGroupSelect(activeNetworkPanel.toString())
    this.changeNetworkFilter(activeNetworkPanel.toString())
  }

  changeNetwork(network) {
    this.props.changefilterPanel(network)
    this.setState({ selectedSubIndustryId: network })
    this.handleSpecialityGroupSelect(network.toString())
    this.changeNetworkFilter(network.toString())
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filters !== this.props.filters) {
      this.setState(this.props.filters);
    }
    if (prevProps.filters.companyName !== this.props.filters.companyName) {
      this.setState({
        searchValue: this.props.filters.companyName,
      });
    }
    if (this.state.selectedSubIndustryId !== prevState.selectedSubIndustryId) {
      const expertiesesList = this.state?.subGroup.filter((value) => value.id == this.state.selectedSubIndustryId && value.expertieses)
      this.setState(
        {
          expertiesesListBySelectedSubIndustry: expertiesesList
        }
      );
    }

    if (this.state.expertiesesListBySelectedSubIndustry !== prevState.expertiesesListBySelectedSubIndustry) {
      this.state.expertiesesListBySelectedSubIndustry.map((value) =>
        this.setState({ expertieseListing: [...value.expertieses] })
      )
    }

    // if(this.state.subIndustryRels !== prevState.subIndustryRels)
    //    console.log('checking.....$$$$$',this.state.subIndustryRels)

    if (this.props.activeNetworkPanel !== prevProps.activeNetworkPanel) {
      if (this.props.activeNetworkPanel.length === 0)
        this.setState({ selectedSubIndustryId: '' })
    }
  }

  // Fetch cities on city input
  fetchCities = (input, callback) => {
    return fetchCities(input, this.state.country.id, callback);
  };

  // Reset all filters
  resetFilters = async () => {
    await this.props.clearFilters();
    this.props.setFirstTab({ type: SET_ALL_COMPANIES_TAB, payload: 1 })
    this.props.changeSubIndstry('')
    this.setState({
      expertiseRels: '',
      // subIndustryRels: '',
      expertieseListing: [],
      selectedSubIndustryId: '',
      // activeNetworkPanel:[]

    })
    this.props.changefilterPanel([]);
    // this.props({clearFilterStatus: true})

    // localStorage.setItem("clearFilter", true)
    this.expertiseList()

    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: '',
      state: {}
    });
  };

  autocompleteSearch = (q) => {
    const url = this.props.filterResults("companyName", q);
    this.props.updateURLAndData(url);
  };
  autocompleteSearchByUserEmail = (q) => {
    console.log("fos_email", q)
    const url = this.props.filterResults("fos_email", q);
    this.props.updateURLAndData(url);
  };

  // Handle Search
  onSearchInput = (e) => {
    this.setState(
      {
        companyName: e.target.value,
      },
      () => {
        this.autocompleteSearchDebounced(this.state.companyName);
      }
    );
  };

  onSearchInputByUserEmail = (e) => {
    this.setState(
      {
        fos_email: e.target.value,
      },
      () => {
        // if (this.state.fos_email?.length > 3) {
        this.autocompleteSearchDebouncedByUserEmail(this.state.fos_email);
        // } else {
        // this.setState({fos_email:''})
        // message.error("Please enter more than 3 charater.")
        // }s
      }
    );
  };

  // Handle Select components
  resetExpertiseFilter = () => {
    const { expertise } = this.state;
    if (expertise.length) {
      let url = this.props.filterResults("classification", "any", "remove");

      return this.props.filterResults(expertise, "any", "remove", url);
    } else {
      return this.props.filterResults("classification", "any", "remove");
    }
  };

  // Handle Select components
  handleSelect = (option, action) => {
    if (!option) {
      this.setState({
        [action.name]: null,
      });

      if (action.name === "country") {
        let url = this.props.filterResults("city", "any", "remove");
        url = this.props.filterResults(action.name, "any", "remove", url);
        this.setState({ city: null });
        console.log('in country....testing..', url)
        return this.props.updateURLAndData(url);
      } else {
        if (
          (this.state.flag && action.name === this.state.expertise) ||
          action.name === "airIndustryExpertise" ||
          action.name === "associateIndustryExpertise" ||
          action.name === "vendorIndustryExpertise"
        ) {
          let url = this.props.filterResults("classification", "any", "remove");
          url = this.props.filterResults(
            this.state.expertise ? this.state.expertise : action.name,
            "any",
            "remove",
            url
          );
          this.setState({ expertise: "" });
          return this.props.updateURLAndData(url);
        } else {
          const url = this.props.filterResults(action.name, "any", "remove");
          return this.props.updateURLAndData(url);
        }
      }
    }
    if (action.name === "expertiseRels") {

      this.setState({
        expertiseRels: option.name
      })
    }
    this.setState({
      [action.name]: option,
    });

    if (option.expertiseName && option.expertiseName.length) {
      let value;
      if (option.expertiseName === "airIndustryExpertise")
        value = "Air Partner";
      if (option.expertiseName === "associateIndustryExpertise")
        value = "Associate";
      else if (option.expertiseName === "vendorIndustryExpertise")
        value = "Vendor";
      let url = this.props.filterResults("classification", "any", "remove");
      url = this.props.filterResults(
        this.state.expertise ? this.state.expertise : option.expertiseName,
        "any",
        "remove",
        url
      );
      url = this.props.filterResults("classification", value, "add", url);
      url = this.props.filterResults(
        option.expertiseName,
        option.id,
        "add",
        url
      );
      this.setState({ expertise: option.expertiseName });
      this.props.updateURLAndData(url);
    } else {
      if (
        action.name === "airIndustryExpertise" ||
        action.name === "associateIndustryExpertise" ||
        action.name === "vendorIndustryExpertise"
      ) {
        this.setState({ expertise: action.name });
      }
      let url = this.props.filterResults(action.name, option.id);
      this.props.updateURLAndData(url);
    }
  };

  findExpertise = () => {
    if (this.state.classification === "Air Partner") {
      return this.state.airIndustryExpertise;
    }
    if (this.state.classification === "Associate") {
      return this.state.associateIndustryExpertise;
    }
    return this.state.vendorIndustryExpertise;
  };

  findExpertiseName = () => {
    if (this.state.classification === "Air Partner") {
      return "airIndustryExpertise";
    }
    if (this.state.classification === "Associate") {
      return "associateIndustryExpertise";
    }
    return "vendorIndustryExpertise";
  };

  findExpertiseOptions = () => {
    const expertiseName = this.findExpertiseName();
    let options = [];
    if (this.state.classification === "") {
      const classifications = [
        "airIndustryExpertise",
        "associateIndustryExpertise",
        "vendorIndustryExpertise",
      ];
      classifications.forEach((elem) => {
        const option = this.props[elem];
        option &&
          option.forEach((val) => {
            val = { ...val, ["expertiseName"]: elem };
            options.push(val);
          });
      });
      return options;
    } else {
      return this.props[expertiseName];
    }
  };

  handleClassificationSelect = (e) => {
    // TODO - Remove all specific industry expertises already selected form query strings
    const { value } = e.target;

    const url = this.resetExpertiseFilter(this.state.classification);
    this.setState({
      expertise: "",
      airIndustryExpertise: null,
      associateIndustryExpertise: null,
      vendorIndustryExpertise: null,
    });
    if (!value) {
      this.setState({
        classification: "",
        flag: true,
      });
      const resultUrl = this.props.filterResults(
        "classification",
        "any",
        "remove",
        url
      );
      return this.props.updateURLAndData(resultUrl);
    }
    this.setState({
      classification: value,
      flag: false,
    });
    const resultUrl = this.props.filterResults(
      "classification",
      value,
      "add",
      url
    );
    return this.props.updateURLAndData(resultUrl);
  };
  handleAllNetwork = () => {
    this.expertiseList()
    this.props.changeSubIndstry('')
    this.setState({
      // subIndustryRels: "",
      flag: true,
      expertieseListing: []
    });

    const url = this.resetExpertiseFilter(this.state.subIndustryRels);

    const resultUrl = this.props.filterResults("subIndustryRels", 'any', 'remove', url);
    return this.props.updateURLAndData(resultUrl);
  }
  handleSpecialityGroupSelect = (e) => {
    // TODO - Remove all specific industry expertises already selected form query strings
    /*     const { value } = e.target; */
    const value = e;
    const url = this.resetExpertiseFilter(this.state.subIndustryRels);
    this.setState({
      expertise: "",
      airIndustryExpertise: null,
      associateIndustryExpertise: null,
      vendorIndustryExpertise: null,
      selectedSubIndustryId: value
    });
    // this is added in case of unselect the same filtered which is selected
    if (value === this.state.subIndustryRels) {
      this.props.changeSubIndstry("")
      this.setState({
        // subIndustryRels: "",
        flag: true,
        expertieseListing: [],
        selectedSubIndustryId: null,
        expertiesesListBySelectedSubIndustry: []
      });
      //  this.setState({ expertieseListing: [] }) // remove expertise list which based on selected newtwork
      this.expertiseList(); // get all expertise

      const resultUrl = this.props.filterResults("subIndustryRels", "any", "remove", url);
      console.log('checking...result url', resultUrl)
      return this.props.updateURLAndData(resultUrl);
    }
    // this is added in case of unselect the same filtered which is selected
    if (!value) {
      // console.log("if..")
      this.props.changeSubIndstry("")
      this.setState({
        // subIndustryRels: "",
        flag: true,
      });
      const resultUrl = this.props.filterResults("subIndustryRels", url);
      console.log('checking...result url', resultUrl)
      return this.props.updateURLAndData(resultUrl);
    } else {
      // console.log("else")
      this.props.changeSubIndstry(value)
      this.setState({
        // subIndustryRels: value,
        flag: false,
      });
      const resultUrl = this.props.filterResults("subIndustryRels", value, url);
      console.log('checking...result url', resultUrl)
      return this.props.updateURLAndData(resultUrl);
    }
  };

  // Handle all ImportExportSelect components
  handleImportExportSelect = (options, action) => {
    const { importCountry, exportCountry } = this.state;
    this.setState({ importExport: options.id });

    if (options.id === "all") {
      if (importCountry || exportCountry) {
        let url = this.props.filterResults("importCountry", "any", "remove");
        url = this.props.filterResults("exportCountry", "any", "remove", url);
        this.setState({ importCountry: null, exportCountry: null });
        this.props.updateURLAndData(url);
      }
    } else if (options.id === "import") {
      if (exportCountry) {
        let url = this.props.filterResults("exportCountry", "any", "remove");
        this.setState({ exportCountry: null });
        this.props.updateURLAndData(url);
      }
    } else {
      if (importCountry) {
        let url = this.props.filterResults("importCountry", "any", "remove");
        this.setState({ importCountry: null });
        this.props.updateURLAndData(url);
      }
    }
  };

  // Handle all MultiSelect components
  handleMultiSelect = (options, action) => {
    if (!options) {
      this.setState({
        [action.name]: null,
      });
      const url = this.props.filterResults(action.name, "any", "remove");
      return this.props.updateURLAndData(url);
    }

    const url = this.props.filterResults(
      action.name,
      options.map((option) => option.id).join()
    );
    this.setState({
      [action.name]: options,
    });
    this.props.updateURLAndData(url);
  };

  // Get filtered Airports (?search)
  getAirports = (input, callback) => {
    axios
      .get(`${base_url}/${api_v}airports?search=${input}`)
      .then((res) => {
        return callback(res.data["hydra:member"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get filtered Airlines (?search)
  getAirlinesForSelect = (input, callback) => {
    axios
      .get(`${base_url}/${api_v}airlines?search=${input}`)
      .then((res) => {
        return callback(res.data["hydra:member"]);
      })
      .catch((err) => console.log(err));
  };
  subGroupList = async () => {
    const { getSubGroupList } = this.props;
    let SubGroupList = await getSubGroupList();



    if (SubGroupList) {
      this.setState({ subGroup: SubGroupList });
    }
  };
  expertiseList = async () => {
    const { getNewExpertieseList } = this.props;
    let expertiseList = await getNewExpertieseList();
    console.log('in expert list', expertiseList);



    if (expertiseList) {
      this.setState({ expertise: expertiseList });
    }
  };

  /*  certificatesList = async () => {
     const { getCertificates } = this.props;
     let certificatesList = await getCertificates();   
   };
  */
  componentDidMount() {
    const searchParams = qs.parse(this.props.history.location.search.slice(1));
    if (searchParams.hasOwnProperty("airIndustryExpertise"))
      this.setState({ expertise: "airIndustryExpertise" });
    else if (searchParams.hasOwnProperty("associateIndustryExpertise"))
      this.setState({ expertise: "associateIndustryExpertise" });
    else if (searchParams.hasOwnProperty("vendorIndustryExpertise"))
      this.setState({ expertise: "vendorIndustryExpertise" });
    const queryParams = new URLSearchParams(searchParams);
    for (const [key, value] of queryParams) {
      if (key === "subIndustryRels") {
        this.props.changeSubIndstry(parseInt(value))
        // this.setState({ subIndustryRels: parseInt(value) });
      }
    }
    // getSubGroupList();
    this.subGroupList();
    this.expertiseList();
    // this.certificatesList()
  }

  blockColor = (id) => this.colorMap[id] || "";
  //  domainArray = (name) => this.domainMap[name]
  render() {
    const {
      companyName,
      fos_email,
      country,
      city,
      classification,
      specializations,
      status,
      selectedStatus,
      importCountry,
      exportCountry,
      importExport,
      originAirport,
      destinationAirport,
      airline,
      subIndustryRels,
      selectedSubIndustryId,
      expertiesesListBySelectedSubIndustry,
      expertieseListing,
      expertiseRels,
      // clearFilterStatus
    } = this.state;

    const { auth, countries, filters, specializationList, airImportCountries, airExportCountries, certificates, common } = this.props;
    console.log("filters......", filters)
    console.log("role", localStorage.getItem("rolesUser"))
    return (
      <aside className="network-sidebar p-6">
        {/* CLEAR ALL SECTION */}
        <section className="border-bottom f-s-b pb-1">
          <h3 className="text-white">Filters</h3>
          <Button onClick={this.resetFilters} /* type="link" */ className="clear-all-btn" size="small">
            Clear all
          </Button>
        </section>

        {/* SEARCH COMPANIES NAME */}
        <section className="mt-6">
          <div className="v-space-1">
            <label className="text-white">Companies</label>
            <Search
              icon="search"
              allowClear
              value={companyName}
              defaultValue={filters.companyName}
              onChange={this.onSearchInput}
              placeholder="Search companies..."
            ></Search>
          </div>
        </section>

        {/* SEARCH USER NAME */}
        {localStorage.getItem("rolesUser") === 'ROLE_ADMIN' &&
          <section className="mt-3">
            <div className="v-space-1">
              <label className="text-white">Users</label>
              <Search
                icon="search"
                allowClear
                value={fos_email}
                defaultValue={filters.fos_email}
                onChange={this.onSearchInputByUserEmail}
                placeholder="Search users..."
              ></Search>
            </div>
          </section>

        }

        {/* Speciality Groups Section  */}
        <section className="sidebar-section">
          <h5 className="sidebar-section-title text-white">
            Networks
          </h5>
          <div className="SpecialityGroupbox">
            {this.state?.subGroup === undefined
              ?
              <Skeleton active />
              :
              (
                <NetworkPanel
                  changePanel={this.changePanel.bind(this)}
                  activeNetworkPanel={this.props.activeNetworkPanel}
                  subGroup={this.state.subGroup}
                  country={country}
                  city={city}
                  countries={countries}
                  handleSelect={this.handleSelect.bind(this)}
                  expertieseListing={expertieseListing}
                  expertiseRels={this.state.expertiseRels}
                  fetchCities={this.fetchCities.bind(this)}
                  common={this.props.common}
                  selectedSubIndustryId={this.state.selectedSubIndustryId}
                  blockColor={this.blockColor.bind(this)}
                  changeNetwork={this.changeNetwork.bind(this)}
                  subIndustryRels={this.props.subIndustryRels}
                />

              )
            }
          </div>
          {/* 
          <div className="v-space-1">
            <label className="text-white marginFilter">Accrediation</label>
            <div className="SpecialityGroupbox">
              {certificates?.length === 0
                ?
                <Skeleton active />
                :
                <Radio.Group
                  buttonStyle="solid"
                  size="small"
                  // value={this.state.subIndustryRels}
                  name="CertificateGroups"
                >
                  {certificates?.map((data) => {
                    return (
                      <Radio.Button key={data?.certificatesKey} value={data?.certificatesValue} onClick={() => this.handleCertificateGroupSelect(data?.id)}>
                        <img
                          src={
                            data?.imageUrl
                          }
                          alt={"accreditation"}
                          className="accrediationImg"
                        />
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              }
            </div>
          </div> */}
          {/* new expertise flow */}
          {/* <div className="v-space-1">
            <label className="text-white">Industry expertise</label>
            <CustomSelect
              placeholder={"Select expertise"}
              key={this.state.classification}
              name={this.findExpertiseName()}
              value={this.findExpertise()}
              isSearchable={false}
              isClearable={true}
              options={this.findExpertiseOptions()}
              getOptionLabel={(option) => option.industryExpertise}
              getOptionValue={(option) => option.id}
              onChange={this.handleSelect}
            ></CustomSelect>
          </div> */}
          {/*  {auth.user.roles.includes("ROLE_ADMIN") && (
            <div className="v-space-1">
              <label className="text-white">Status</label>
              <CustomSelect
                placeholder={"Status"}               
                value={status}
                name="status"
                isSearchable={false}
                isClearable={true}
                options={statusList}
                getOptionValue={(option) => option.id}
                onChange={this.handleSelect}
              ></CustomSelect>
            </div>
          )} */}
        </section>
        {/* MY AIR PARTNER SECTION */}
        <section className="sidebar-section">
          {/* <h5 className="sidebar-section-title text-white">My air partner</h5>
          <div className="v-space-1 side-filter-classification">
            <Radio.Group
              onChange={this.handleClassificationSelect}
              value={classification}
            >
              <Radio style={radioStyle} value="">
                Any
              </Radio>
              <Radio style={radioStyle} value="Air Partner">
                Air Partner
              </Radio>
              <Radio style={radioStyle} value="Associate">
                Associate
              </Radio>
              <Radio style={radioStyle} value="Vendor">
                Vendor
              </Radio>
            </Radio.Group>
          </div>*/}


          {/*    <div className="v-space-1">
            <label className="text-white marginFilter">Expertise</label>
            <CustomSelect

              placeholder={"Expertise"}
              value={expertiseRels}
              name="expertiseRels"
              isSearchable={false}
              isClearable={true}
              //options={expertieseListing}
              options={expertieseListing.length !== 0 ? expertieseListing : this.state.expertise}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={this.handleSelect}
            ></CustomSelect>
          </div> */}

          <div className="v-space-1 country-label">
            {/*      <label className="text-white">Tradelane</label> */}
            <label className="sidebar-section-title text-white">Tradelane specialization</label>
            <CustomSelect
              placeholder={"Tradelane"}
              value={specializations}
              name="specializations"
              isSearchable={false}
              isClearable={true}
              options={specializationList}
              getOptionLabel={(option) => option.specialization}
              getOptionValue={(option) => option.id}
              onChange={this.handleSelect}
            ></CustomSelect>
          </div>
          {/*   {auth.user.roles.includes("ROLE_ADMIN") && (
            <div className="v-space-1">
              <label className="text-white">Status</label>
              <CustomSelect
                placeholder={"Status"}
                value={status}
                name="status"
                isSearchable={false}
                isClearable={true}
                options={statusList}
                getOptionValue={(option) => option.id}
                onChange={this.handleSelect}
              ></CustomSelect>
            </div>
          )} */}
          {/* {!([7, 3, 8, 2].includes(+this.state.selectedSubIndustryId)) && ( */}
          <>
            <div className="v-space-1">
              <label className="text-white">Import/Export</label>
              <CustomSelect
                name="importExport"
                isSearchable={false}
                isClearable={false}
                defaultValue={importExportList[0]}
                options={importExportList}
                getOptionValue={(option) => option.id}
                onChange={this.handleImportExportSelect}
              ></CustomSelect>
            </div>
            {importExport !== "export" && importExport !== "all" && airImportCountries?.length ? (
              <div className="v-space-1">
                <label className="text-white">Country</label>
                <CustomSelect
                  name="importCountry"
                  isSearchable={true}
                  isClearable={true}
                  options={airImportCountries ? airImportCountries : []}
                  value={importCountry}
                  getOptionLabel={(option) => option?.countryname}
                  getOptionValue={(option) => option?.id}
                  onChange={this.handleSelect}
                ></CustomSelect>
              </div>
            ) : null}

            {importExport !== "import" && importExport !== "all" && airExportCountries?.length ? (
              <div className="v-space-1">
                <label className="text-white">Country</label>
                <CustomSelect
                  name="exportCountry"
                  isSearchable={true}
                  isClearable={true}
                  options={airExportCountries ? airExportCountries : []}
                  value={exportCountry}
                  getOptionLabel={(option) => option?.countryname}
                  getOptionValue={(option) => option?.id}
                  onChange={this.handleSelect}
                ></CustomSelect>
              </div>
            ) : null}
          </>
          {/* )} */}
          {/* <div className="v-space-1">
            <label className="text-white">Sub-Group</label>
            <CustomSelect
              placeholder={"Tradelane"}
              value={subIndustryRels}
              name="subIndustryRels"
              isSearchable={false}
              isClearable={true}
              options={specializationList}
              getOptionLabel={(option) => option.specialization}
              getOptionValue={(option) => option.id}
              onChange={this.handleSelect}
            ></CustomSelect>
          </div> */}
        </section>
        {/* MY AIRLINES SECTION */}
        <section className="sidebar-section marginFilter">
          {/* <h5 className="sidebar-section-title text-white">My airlines</h5> */}
          {/* {!([7, 3, 8, 2].includes(+this.state.selectedSubIndustryId)) && ( */}
          <>
            <div className="v-space-1">
              <label className="text-white">{/* Origin Airport */}Top import airports</label>
              <CustomSelect
                async={true}
                value={originAirport}
                name="originAirport"
                cacheOptions
                loadOptions={this.getAirports}
                formatOptionLabel={formatAirportOption}
                isClearable={false}
                noOptionsMessage={(input) => "Please enter 2 or more characters"}
                isMulti
                getOptionValue={(option) => option.id}
                onChange={this.handleMultiSelect}
              ></CustomSelect>
            </div>
            <div className="v-space-1">
              <label className="text-white">{/* Destination Airport */}Top export airports</label>
              <CustomSelect
                async={true}
                value={destinationAirport}
                name="destinationAirport"
                cacheOptions
                loadOptions={this.getAirports}
                formatOptionLabel={formatAirportOption}
                noOptionsMessage={(input) => "Please enter 2 or more characters"}
                isClearable={false}
                isMulti
                getOptionValue={(option) => option.id}
                onChange={this.handleMultiSelect}
              ></CustomSelect>
            </div>
          </>
          {/* )} */}
          <div className="v-space-1">
            <label className="text-white">Top airlines</label>
            <CustomSelect
              async={true}
              name="airline"
              value={airline}
              cacheOptions
              loadOptions={this.getAirlinesForSelect}
              noOptionsMessage={(input) => "Please enter 2 or more characters"}
              formatOptionLabel={formatAirlineOption}
              isMulti
              getOptionValue={(option) => option.id}
              onChange={this.handleMultiSelect}
            ></CustomSelect>
          </div>
        </section>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.data.countries,
  airImportCountries: state.data.airImportCountries,
  airExportCountries: state.data.airExportCountries,
  cities: state.data.cities,
  filters: state.data.filters,
  specializationList: state.data.specializationList,
  // airIndustryExpertise: state.data.airIndustryExpertise,
  // associateIndustryExpertise: state.data.associateIndustryExpertise,
  // vendorIndustryExpertise: state.data.vendorIndustryExpertise,
  auth: state.auth,
  certificates: state?.data?.certificateList,
  common: state.common
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    clearFilters,
    setFirstTab,
    // setAllcompaniesTab,
    getSubGroupList,
    // getCertificates,
    getNewExpertieseList
  })
)(NetworkSidebar);