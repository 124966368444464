import React, { Component } from "react";
import { connect } from "react-redux";
import { getCompany, clearCompany } from "actions/companyActions";
import {
  getCountryCitiesList,
  updateNetworkCompany,
  getSubGroupList,
} from "actions/dataActions";
import Header from "components/company-profile/header/Header";
import Content from "./content/Content";
import SkeletonLoading from "./SkeletonLoading";
import goBackIcon from "theme/assets/goback-icon.svg";
import { logoData } from "actions/dataActions";
import { Button } from "antd";
class Profile extends Component {
  state = {
    companyId: 10,
    showEdit: false,
  };
 /*  subGroupList = async () => {
    const { getSubGroupList } = this.props;
    let SubGroupList = await getSubGroupList();

    if (SubGroupList) {
      this.setState({ subGroup: SubGroupList });
    }
  }; */
  componentDidMount() {
    window.scrollTo(0, 0);

    const companyId = this.props.match.params.id ?? this.props.companyId;

    this.setState({
      companyId,
    });
   
    if (this.props?.auth?.logoData  === undefined) {
      this.props.logoData(companyId);
    }
    this.props.getCompany(companyId);
    // this.subGroupList();
  }

  componentDidUpdate(prevProps, prevState) {
    const companyId = this.props.match.params.id;
    if (this.state.companyId !== companyId) {
      this.setState(
        {
          companyId,
        },
        () => {
          this.props.getCompany(companyId);          
          if (this.props?.auth?.logoData === undefined) {
            this.props.logoData(companyId);
          }
        }
      );
    }

    if (this.props.data !== null) {
      if (prevProps.data !== this.props.data) {
        const { airExportCountry, airImportCountry, countries } =
          this.props.data;
        let contryIds = [
          "countryId[]=" + countries.id,
          ...airExportCountry.map((a) => "countryId[]=" + a.country.id),
          ...airImportCountry.map((a) => "countryId[]=" + a.country.id),
        ];

        this.props.getCountryCitiesList(contryIds.join("&"));
      }
    }
  }

  toggleEdit = () => {
    this.setState((prevState) => ({
      showEdit: !prevState.showEdit,
    }));
  };

  render() {
    const { data, cities, auth, loading } = this.props;

    const canEdit =
      JSON.parse(localStorage.companyInfo).editProfile === true &&
      (auth.user.roles.includes("ROLE_ADMIN") ||
        JSON.parse(localStorage.companyInfo).company === data?.id);

    let profileSection;

    if (loading || data === null) {
      profileSection = <SkeletonLoading />;
    } else {
      profileSection = (
        <>
          <div className="network-header f-s-b">
            <h4
              className="page-title go-back"
              onClick={() => { window.history.back() }}
            >
              <img src={goBackIcon} className="pageIcon" />
              Go Back
            </h4>
            <div className="rightBox">
              {
                data?.subIndustry.map((subindustryItem) => {
                  console.log(subindustryItem)
                  return (
                    <>
                      <img className="headernetworkimage" src={subindustryItem.industryLogo} />
                      <img className="headernetworklogo" src={subindustryItem.deactiveImg} />
                    </>

                  )
                })

              }
            </div>




            {/*  {canEdit && (
              <Button
                className="edit-profile-btn"
                // type="primary"
                // className="ml-auto"
                onClick={this.toggleEdit}
              >
                EDIT PROFILE
              </Button>
            )} */}
          </div>
          <div>
            <Header
              name={data.companyName}
              logo={data.logo}
              country={data.countries}
              city={data.city || ""}
              companyId={data.id}
              classification={data.companySettings[0].classification}
              auth={auth}
              showEdit={this.state.showEdit}
              canEdit={canEdit}
              toggleEdit={this.toggleEdit}
              companySettings={data.companySettings}
            ></Header>
            <Content company={data} cities={cities} auth={auth} />
          </div>
        </>
      );
    }

    return (
      <div className="pageContainer company-profile">{profileSection}</div>
    );
  }
}

Profile.defaultProps = {
  companyId: 10,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.company.data,
  cities: state.data.cities,
  loading: state.company.loading,
});

export default connect(mapStateToProps, {
  getCompany,
  logoData,
  clearCompany,
  getCountryCitiesList,
  updateNetworkCompany,
  getSubGroupList,
})(Profile);
