import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { clearCurrentProfile } from "./actions/profileActions";

import { Provider } from "react-redux";
import store from "./store";
import PrivateRoute from "components/auth/PrivateRoute";
import Header from "components/layout/Header";
import Login from "components/auth/Login";
import Register from "components/auth/Registeration/Register";
import Network from "components/network/Network";
// import Accounting from "components/accounting/Network";
import CompanyEdit from "components/company-edit/CompanyEdit";
import UploadPreview from "components/ui-elements/UploadPreview";
import GlobalError from "components/ui-elements/GlobalError";
import Invitations from "components/invitations/Invitations";
import CreditAlerts from "components/credit-alerts/CreditAlerts";
import Promos from "components/promos/Promos";
import Insurance from "components/insurance/Insurance";
import Partners from "components/partners";
import Profile from "components/company-profile/Profile";
import Dashboard from "components/dashboard";
import NexHub from "components/nexHub";
import MySendle from "components/mysendle";
import Form from "components/form";
import ForgotPassword from "components/auth/ForgotPassword";
import ResetPassword from "components/auth/ResetPassword";
import PublicRoute from "components/auth/PublicRoute";
import Solution from "components/solution/Solution";

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);

  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = "/login";
  }


  // const userStatus = localStorage.status;
  // if (userStatus === 'Not Finished') {
  //   // this.props.history.push("/register");
  //   window.location.href = "/register";
  // }
}

if (window.screen.width <= 768 && (!localStorage.continueDesktop || localStorage.continueDesktop !== "1")) {
  localStorage.setItem("continueDesktop", 0)
}

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          {/* <Header /> */}
          <main className="page">
            <Switch>
              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute exact path="/register" component={Register} />
              <PublicRoute exact path="/forgotPassword" component={ForgotPassword} />
              <PublicRoute exact path="/resetPassword" component={ResetPassword} />

              {/*  <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/resetPassword" component={ResetPassword} /> */}
              {/* <Route exact path="/insurance" component={Insurance} /> */}
              <PrivateRoute
                exact
                path="/dashboard"
                title="Dashboard"
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path="/nex-hub"
                title="NexHub"
                component={NexHub}
              />
              <PrivateRoute
                exact
                path="/mysendle"
                title="mysendle"
                component={MySendle}
              />
              <PrivateRoute
                exact
                path="/question"
                title="Dashboard"
                component={Form}
              />
              <PrivateRoute
                exact
                path="/network"
                title="Network"
                component={Network}
              />

              <PrivateRoute
                exact
                path="/accounting"
                title="Accounting"
                component={Network}
              />

              <PrivateRoute
                exact
                path="/edit-company"
                component={CompanyEdit}
              />
              <PrivateRoute
                exact
                path="/invitations"
                title="Invitations"
                component={Invitations}
              />
              <PrivateRoute
                exact
                path="/credit-alerts"
                title="Credit Alerts"
                component={CreditAlerts}
              />
              <PrivateRoute
                exact
                path="/promos"
                title="Promos"
                component={Promos}
              />
              <PrivateRoute
                exact
                path="/application"
                title="Application"
                component={Form}
              />

              {/* <PrivateRoute
                path="/insurance"
                title="Insurance"
                component={Insurance}
              />
               <PrivateRoute
                path="/partners"
                title="Insurance"
                component={Partners}
              /> */}
              <PrivateRoute
                exact
                path="/company-profile/:id"
                title="Company Profile"
                component={Profile}
              />
              <PrivateRoute
                path="/solution"
                title="Solution"
                component={Solution}
              />
              <PrivateRoute
                path="/"
                title="Network"
                component={Network}
              />
            </Switch>
          </main>
          <UploadPreview></UploadPreview>
          <GlobalError></GlobalError>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
